import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from "react-router-dom"

class VerifySimak extends React.Component {
  render() {
    return(
      <div className="accordion">
        <div className="accordion-item">
          <h2 className="accordion-header" id="panelsStayOpen-headingOne">
            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
              Verifikasi Data Simak Pengawasan Bangunan
            </button>
          </h2>
          <div id="panelsStayOpen-collapseOne" className="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingOne">
            <div className="accordion-body row g-2">
              <form className="row g-2" id="form-verifikasi">
                <div className="wrapper-nomer-simak col-sm-12 col-md-6 col-lg-6">
                  <div className="form-floating mb-2" id="nomer-simak">
                    <input type="text" className="form-control" id="nomer-simak" placeholder="abcde" defaultValue="640/1916 PR/436.7.4/2023" readOnly={true} />
                    <label className="form-label">Nomor</label>  
                  </div>
                  <div className="form-floating" id="tanggal-daftar-simak">
                    <input type="text" className="form-control" id="tanggal-daftar-simak" placeholder="abcde" defaultValue="10 September 2023" readOnly={true} />
                    <label className="form-label">Tanggal Daftar Simak</label>  
                  </div>                
                </div>
                <div className="form-floating alamat-persil col-sm-12 col-md-6 col-lg-6">
                  <textarea className="form-control" id="alamat-persil" placeholder="abcde" style={{ height: '125px' }} defaultValue="Jalan Panglima Sudirman No.1"></textarea>
                  <label className="form-label">Alamat Persil</label>
                </div>
                <div className="form-floating keterangan_sidak">
                  <textarea className="form-control" id="keterangan_sidak" placeholder="abcde" style={{ height: '75px' }} defaultValue="Graha Warna Warni"></textarea>
                  <label className="form-label">Keterangan Sidak Lapangan</label>
                </div>          
                <div className="baris"> <hr /> </div>
                <div className="wrapper-radio-verifikasi col-sm-12 col-md-4 col-lg-4">
                  <div className="imb">
                    <span className="col-form-label">Status IMB : </span>
                    <div className="form-check">
                      <input className="form-check-input" type="radio" name="imbRadios" id="gridRadios1" value="option1" defaultChecked />
                      <label className="form-check-label" htmlFor="gridRadios1">
                        Memiliki
                      </label>
                    </div>
                    <div className="form-check">
                      <input className="form-check-input" type="radio" name="imbRadios" id="gridRadios2" value="option2" />
                      <label className="form-check-label" htmlFor="gridRadios2">
                        Tidak Memiliki
                      </label>
                    </div>   
                  </div>
                  <div className="bangunan">
                    <span className="col-form-label">Kesesuaian Bangunan : </span>
                    <div className="form-check">
                      <input className="form-check-input" type="radio" name="bangunanRadios" id="gridRadios3" value="option3" defaultChecked />
                      <label className="form-check-label" htmlFor="gridRadios3">
                        Sesuai
                      </label>
                    </div>
                    <div className="form-check">
                      <input className="form-check-input" type="radio" name="bangunanRadios" id="gridRadios4" value="option4" />
                      <label className="form-check-label" htmlFor="gridRadios4">
                        Tidak Sesuai
                      </label>
                    </div>   
                  </div>
                </div>
                <div className="wrapper-dokumentasi-keterangan col-sm-12 col-md-8 col-lg-8">
                  <div className="form-floating keterangan">
                    <textarea className="form-control" id="keterangan" placeholder="abcde" style={{ height: '75px' }}></textarea>
                    <label className="form-label">Keterangan Hasil Verifikasi</label>
                  </div>                                                   
                  <div className="form-group dokumentasi mt-2">
                    <label htmlFor="formFile" className="form-label">Upload Dokumentasi Hasil Verifikasi</label>
                    <input className="form-control" type="file" id="formFile" />
                  </div>               
                </div>
                <div className="form-floating usulan">
                  <span className="col-form-label">Usulan Tindak Lanjut : </span>
                  <div className="wrapper-pilihan-1 d-flex flex-row">
                    <div className="form-check col-sm-12 col-md-4 col-lg-4">
                      <input className="form-check-input" type="radio" name="usulanRadios" id="gridRadios5" value="option5" defaultChecked />
                      <label className="form-check-label" htmlFor="gridRadios5">
                        Menghentikan Pembangunan
                      </label>                  
                    </div>
                    <div className="form-check col-sm-12 col-md-4 col-lg-4">
                      <input className="form-check-input" type="radio" name="usulanRadios" id="gridRadios6" value="option6" />
                      <label className="form-check-label" htmlFor="gridRadios6">
                        Memiliki IMB
                      </label>
                    </div>          
                    <div className="form-check col-sm-12 col-md-4 col-lg-4">
                      <input className="form-check-input" type="radio" name="usulanRadios" id="gridRadios7" value="option7" />
                      <label className="form-check-label" htmlFor="gridRadios7">
                        Membongkar Sendiri Bangunan
                      </label>
                    </div>
                  </div>           
                  <div className="wrapper-pilihan-2 d-flex flex-row">
                    <div className="form-check col-sm-12 col-md-4 col-lg-4">
                      <input className="form-check-input" type="radio" name="usulanRadios" id="gridRadios9" value="option9" />
                      <label className="form-check-label" htmlFor="gridRadios9">
                        Tidak Ditindaklanjuti
                      </label>
                    </div>                        
                    <div className="form-check col-sm-12 col-md-8 col-lg-8">
                      <input className="form-check-input" type="radio" name="usulanRadios" id="gridRadios8" value="option8" />
                      <label className="form-check-label" htmlFor="gridRadios8">
                        Menyesuaikan Bangunan Sesuai Dengan Ketentuan IMB atau Memliki IMB Sesuai Pelaksanaan Pembangunan Di Lokasi
                      </label>
                    </div>                                                   
                  </div>
                </div>            
              </form>                
              <div className="button-submit">
                <button type="submit" className="btn btn-success w-100">
                    <FontAwesomeIcon icon="fa-solid fa-floppy-disk" fixedWidth /> S I M P A N
                </button>
              </div>    
              <div className="button-back">
                <Link to="/verifikasihomepage">
                <button type="button" id="btnBack" className="btn btn-outline-success w-100">
                  <FontAwesomeIcon icon="fa-solid fa-arrow-left" fixedWidth /> K E M B A L I
                  </button>
                </Link>
              </div>                                      
            </div>
          </div>                 
        </div>        
      </div>
    )
  }
}

export default VerifySimak