import React from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from "react-router-dom"

class ClarifySimak extends React.Component {
  render() {
    return(
      <div className="accordion">
        <div className="accordion-item">
          <h2 className="accordion-header" id="panelsStayOpen-headingOne">
            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
              Klarifikasi Simak Pengawasan Bangunan
            </button>
          </h2>
          <div id="panelsStayOpen-collapseOne" className="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingOne">
            <div className="accordion-body row g-2">
              <div className="wrapper-surat-peringatan col-sm-12 col-md-6 col-lg-6">
                <div className="form-floating mb-2" id="nomer-sp">
                  <input type="text" className="form-control" id="nomer-sp" placeholder="abcde" value="188.4/00001/436.7.4/2023" readOnly={true} />
                  <label className="form-label">Nomor Surat Peringatan</label>             
                </div>
                <div className="form-floating" id="tanggal-daftar-simak">
                  <input type="text" className="form-control" id="tanggal-daftar-simak" placeholder="abcde" value="17 September 2023" readOnly={true} />
                  <label className="form-label">Tanggal Terbit Surat</label>  
                </div>                
              </div>
              <div className="form-floating alamat-persil col-sm-12 col-md-6 col-lg-6">
                <textarea className="form-control" id="alamat-persil" placeholder="abcde" style={{ height: '125px' }}>Jl. Panglima Sudirman No.1</textarea>
                <label className="form-label">Alamat Persil</label>
              </div>
              <div className="form-floating keterangan_sidak">
                <textarea className="form-control" id="keterangan_sidak" placeholder="abcde" style={{ height: '100px' }}>Belum Membayar IMB</textarea>
                <label className="form-label">Keterangan Tindak Lanjut</label>
              </div>                          
              <div className="baris"><hr/></div>
              <div className="form-floating hasil-klarifikasi col-sm-12 col-md-6 col-lg-6">
                <textarea className="form-control" id="hasil-klarifikasi" placeholder="abcde" style={{ height: '145px' }}></textarea>
                <label className="form-label">Hasil Klarifikasi</label>
              </div>
              <div className="wrapper-deadline-dokumentasi col-sm-12 col-md-6 col-lg-6">
                <div className="form-floating tgl-tenggat-waktu mb-3">
                  <input type="date" className="form-control" id="tgl-tenggat-waktu" placeholder="00/00/0000" defaultValue="14/01/2023" />
                  <label className="form-label">Tenggat Waktu</label>
                </div>                                                                                        
                <div className="form-group dokumentasi-klarifikasi">
                  <label htmlFor="formFile" className="form-label">Upload Dokumentasi Klarifikasi</label>
                  <input className="form-control" type="file" id="formFile" />
                </div> 
              </div>

              <div className="button-submit py-2">
                <button type="submit" className="btn btn-success w-100">
                    <FontAwesomeIcon icon="fa-solid fa-floppy-disk" fixedWidth /> S I M P A N
                </button>
              </div>
              <div className="button-back">
                <Link to="/klarifikasihomepage">
                <button type="button" id="btnBack" className="btn btn-outline-success w-100">
                  <FontAwesomeIcon icon="fa-solid fa-arrow-left" fixedWidth /> K E M B A L I
                  </button>
                </Link>
              </div>                  
            </div>
          </div>                 
        </div>        
      </div>      
    )
  }
}

export default ClarifySimak