import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from "react-router-dom"

class SidebarArsip extends React.Component {
  render() { 
    return(
      <div className="accordion">
        <div className="accordion-item">
          <h2 className="accordion-header" id="panelsStayOpen-headingOne">
            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsMenuStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsMenuStayOpen-collapseOne">
              Menu
            </button>
          </h2>
          <div id="panelsMenuStayOpen-collapseOne" className="accordion-collapse collapse show" aria-labelledby="panelsMenuStayOpen-headingOne">
            <div className="accordion-body">
              <div className="wrapper-menu row g-2">                    
                  <div className="list-menu">
                    <Link to="/arsiphomepage" style={{ textDecoration: 'none' }}>
                      <p className="text-menu" style={{ color: 'black' }}> <FontAwesomeIcon icon="fa-solid fa-gauge-high" color="black" style={{ marginRight: '10px' }} /> 
                      Home
                      </p>
                    </Link>
                  </div>           
                  <div className="list-menu">
                    <Link to="/arsiphomepage" style={{ textDecoration: 'none' }}>
                    <p className="text-menu" style={{ color: 'black' }}> <FontAwesomeIcon icon="fa-solid fa-box-archive" color="black" style={{ marginRight: '10px' }} /> 
                      Arsip Pengawasan
                      </p>
                    </Link>
                  </div>                                                               
                  <div className="list-menu">
                    <Link to="/logout" style={{ textDecoration: 'none' }}>
                      <p className="text-menu" style={{ color: 'black' }}> <FontAwesomeIcon icon="fa-solid fa-right-from-bracket" color="black" style={{ marginRight: '10px' }} />
                      Logout
                      </p>
                    </Link>
                  </div>                                                                                                        
              </div>
            </div>
          </div>          
        </div>
      </div>
    )
  }
}

export default SidebarArsip;