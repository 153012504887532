import React from 'react'
import Cookies from 'universal-cookie'

class Logout extends React.Component {
  render() {
    const cookies = new Cookies()
    cookies.remove('udatxu', { path: '/' })
    window.location.reload()

    return("")
  }
}

export default Logout