import React from 'react'
import Axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from "react-router-dom"
import Swal from 'sweetalert2'
import Cookies from 'universal-cookie'

import { saveAs } from 'file-saver'
const cookies = new Cookies()

let formData = new FormData()

class AddArchive extends React.Component {
  state = {
    kode_daftar : localStorage.getItem('kode_daftar'),
    tgl_daftar : localStorage.getItem('tgl_daftar'),    
    jenis_bangunan : localStorage.getItem('jenis_bangunan'),
    alamat_persil : localStorage.getItem('alamat_persil'),    
    kecamatan_persil : localStorage.getItem('kecamatan_persil'),
    kelurahan_persil : localStorage.getItem('kelurahan_persil'),
    kode_pos_persil : localStorage.getItem('kode_pos_persil'),
    kawasan_persil : localStorage.getItem('kawasan_persil'),    
    keterangan_register_persil : localStorage.getItem('keterangan_register_persil'),
    status_imb: localStorage.getItem('status_imb'),
    status_kesesuaian_bangunan : localStorage.getItem('status_kesesuaian_bangunan'),
    status_usulan: localStorage.getItem('status_usulan'),
    keterangan_verifikasi: localStorage.getItem('keterangan_verifikasi'),
    dok_verifikasi_status: false
  }

  changeRadiosIMB = (event) => {
    event.preventDefault()

    console.log("IMB Yang Mana ? ", event.target.value)
    this.setState({ status_imb: event.target.value })    
  }

  changeRadiosKesesuaianBangunan = (event) => {
    event.preventDefault()

    console.log("Bangunan Sesuai ? ", event.target.value)
    this.setState({ status_kesesuaian_bangunan: event.target.value })        
  }

  changeRadiosUsulan = (event) => {
    event.preventDefault()

    console.log("Bangunan Sesuai ? ", event.target.value)
    this.setState({ status_usulan: event.target.value })            
  }

  keteranganVerifikasiChange = (event) => {
    event.preventDefault()

    if( event.target.value !== null || event.target.value !== '' ) {
      this.setState({ keterangan_verifikasi : event.target.value })
    }
  }

  dok_verifikasi_file_change = (event) => {
    event.preventDefault()

    if( event.target.files[0] !== null || event.target.files[0] !== ''  ) {
      formData.append("dok_verifikasi_file", event.target.files[0])
      this.setState({ dok_verifikasi_status: true })      
    }
  }
  
  saveNomerSimak = async(event) => {
    event.preventDefault()

    if( !cookies.get('udatxu') ) {
      Swal.fire({
        title: 'Info!',
        text: 'Login Expired. Kindly Re-Login',
        icon: 'info',
        confirmButtonText: 'Okay',
        confirmButtonColor: 'Orange',
        allowOutsideClick: true,
        backdrop: true,
        allowEscapeKey: true,
        allowEnterKey: true            
      }).then(result =>  {
        if(result.isConfirmed) {          
          window.location.reload()
        }
      })  
    } else {
      const datax = await Axios({
        url: 'http://localhost:7070/cetakSimak',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          "Authorization": 'Bearer ' + cookies.get('udatxu').token
        },
        data: JSON.stringify({
          kode_daftar: this.state.kode_daftar, 
          alamat_persil: this.state.alamat_persil
        })   
      })

        /* response from the server */
        if( datax.data.status === 'success' ) {
          Swal.fire({
            title: 'Success!',
            text: datax.data.message,
            icon: 'success',
            confirmButtonText: 'OKAY',
            confirmButtonColor: 'green',
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false            
          })
        } else {
          Swal.fire({
            title: 'Error!',
            text: datax.data.message,
            icon: 'error',
            confirmButtonText: 'OKAY',
            confirmButtonColor: 'Orange',
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false            
          }).then(result =>  {
            if(result.isConfirmed) {
              window.location.reload()
            }
          })
        } 
    }
  }

  cetakSimak = (event) => {
    event.preventDefault()

    saveAs('http://localhost:7070/uploads/'+this.state.kode_daftar+'/'+this.state.kode_daftar+'-simak.docx', "output.docx")    
  }

  render() {
    // localStorage.removeItem('kode_daftar')
    // localStorage.removeItem('tgl_daftar')    
    // localStorage.removeItem('jenis_bangunan')
    // localStorage.removeItem('alamat_persil')
    // localStorage.removeItem('kecamatan_persil')
    // localStorage.removeItem('kelurahan_persil')
    // localStorage.removeItem('kode_pos_persil')
    // localStorage.removeItem('kawasan_persil')            
    // localStorage.removeItem('keterangan_register_persil')
    return(
      <div className="accordion">
        <div className="accordion-item">
          <h2 className="accordion-header" id="panelsStayOpen-headingOne">
            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
              Verifikasi Data Simak Pengawasan Bangunan
            </button>
          </h2>
          <div id="panelsStayOpen-collapseOne" className="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingOne">
            <div className="accordion-body row g-2">
              <div className="row g-2" id="form-archive">
                <h4>Info Pendataan Lapangan</h4>
                <div className="wrapper-kode-daftar col-sm-12 col-md-6 col-lg-6">
                  <div className="form-floating mb-2" id="kode-daftar">
                    <input type="text" className="form-control" id="kode-daftar" placeholder="abcde" defaultValue={this.state.kode_daftar} readOnly={true} />
                    <label className="form-label">Kode Daftar</label>  
                  </div>
                  <div className="form-floating" id="tanggal-daftar-simak">
                    <input type="text" className="form-control" id="tanggal-daftar-simak" placeholder="abcde" defaultValue={this.state.tgl_daftar} readOnly={true} />
                    <label className="form-label">Tanggal Daftar Simak</label>  
                  </div>                
                </div>
                <div className="form-floating alamat-persil col-sm-12 col-md-6 col-lg-6">
                  <textarea className="form-control" id="alamat-persil" placeholder="abcde" style={{ height: '125px' }} defaultValue={ this.state.alamat_persil + ", Kecamatan " + this.state.kecamatan_persil + ", Kelurahan " + this.state.kelurahan_persil + ", " + this.state.kawasan_persil + " - " + this.state.kode_pos_persil } readOnly={true}></textarea>
                  <label className="form-label">Alamat Persil</label>
                </div>
                <div className="form-floating keterangan_sidak">
                  <textarea className="form-control" id="keterangan_sidak" placeholder="abcde" style={{ height: '100px' }} defaultValue={this.state.keterangan_register_persil} readOnly={true}></textarea>
                  <label className="form-label">Keterangan Sidak Lapangan</label>
                </div>          
                <div className="baris"> <hr /> </div>
                <h4>Info Verifikasi</h4>
                <div className="wrapper-verifikasi col-sm-12 col-md-6 col-lg-6">
                  <div className="form-floating mb-2" id="status_imb">
                    <input type="text" className="form-control" id="status_imb" placeholder="abcde" defaultValue={this.state.status_imb} readOnly={true} />
                    <label className="form-label">Status IMB</label>  
                  </div>
                  <div className="form-floating mb-2" id="status_kesesuaian_bangunan">
                    <input type="text" className="form-control" id="status_kesesuaian_bangunan" placeholder="abcde" defaultValue={this.state.status_kesesuaian_bangunan} readOnly={true} />
                    <label className="form-label">Kesesuaian Bangunan</label>  
                  </div>                                    
                </div>
                <div className="form-floating keterangan_verifikasi col-sm-12 col-md-6 col-lg-6">
                  <textarea className="form-control" id="keterangan_verifikasi" placeholder="abcde" style={{ height: '125px' }} defaultValue={this.state.keterangan_verifikasi} readOnly={true}></textarea>
                  <label className="form-label">Keterangan Verifikasi</label>
                </div>                
                <div className="form-floating mb-2" id="status_usulan">
                  <textarea className="form-control" id="status_usulan" placeholder="abcde" style={{ height: '100px' }} defaultValue={this.state.status_usulan} readOnly={true} ></textarea>
                  <label className="form-label">Usulan</label>  
                </div>                
                <div className="baris"> <hr /> </div>
                <h4>Ceklis SIMAK (Cetak)</h4>
                <div className="form-floating col-sm-12 col-md-4 col-lg-4" id="nomor_simak">
                  <input type="text" className="form-control" id="nomor_simak" placeholder="abcde" value={this.state.nomor_simak} onChange={this.handleNomorSimakChange} required />
                  <label className="form-label">Nomor SIMAK</label>  
                </div>
                <div className="button-simpan-simak col-sm-12 col-md-4 col-lg-4">
                  <button type="button" className="btn btn-outline-dark w-100 h-100" onClick={this.saveNomerSimak}>
                    <FontAwesomeIcon icon="fa-solid fa-floppy-disk" fixedWidth /> S I M P A N
                  </button>
                </div>                    
                <div className="button-cetak-simak col-sm-12 col-md-4 col-lg-4">
                  <button type="button" className="btn btn-dark w-100 h-100" onClick={this.cetakSimak}>
                    <FontAwesomeIcon icon="fa-solid fa-print" fixedWidth /> C E T A K
                  </button>
                </div>
                <div className="baris"> <hr /> </div>
                <h4>Unggah SIMAK Tertandatangan</h4>
                <div className="form-group simak-signed col-sm-12 col-md-8 col-lg-8">
                  <label htmlFor="formFile" className="form-label">Upload SIMAK Tertandatangan</label>
                  <input className="form-control" type="file" id="simak_signed_file" name="simak_signed_file" onChange={this.simak_signed_file_change} required />
                </div>                
                <div className="button-upload-simak col-sm-12 col-md-4 col-lg-4">
                  <button type="button" className="btn btn-outline-dark w-100 h-100">
                    <FontAwesomeIcon icon="fa-solid fa-file-arrow-up" fixedWidth /> U N G G A H
                  </button>
                </div>                
              </div>                
              <div className="baris"> <hr /> </div>
              <div className="button-back">
                <Link to="/arsiphomepage">
                <button type="button" id="btnBack" className="btn btn-outline-success w-100">
                  <FontAwesomeIcon icon="fa-solid fa-arrow-left" fixedWidth /> K E M B A L I
                  </button>
                </Link>
              </div>                                      
            </div>
          </div>                 
        </div>        
      </div>
    )
  }
}

export default AddArchive