import React from 'react'

class Sidebar extends React.Component {
  render() { 
    return(
      <div className="card" id="sidebar">
        <div className="card-body">
          
        </div>
      </div>
    )
  }
}

export default Sidebar;