import React from 'react'
import Axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from "react-router-dom"
import Swal from 'sweetalert2'
import ReactPaginate from 'react-paginate'
import Cookies from 'universal-cookie'
import { saveAs } from 'file-saver'
import date from 'date-and-time';
import id from 'date-and-time/locale/id';

import '../../Assets/Styles/DashLapangan.css'

date.locale(id)
const cookies = new Cookies()

class DashArsip extends React.Component {
  state = {
    dataNumber: 0,
    entries: '',    
    dataMap: [],
    perPage: 5,
    offset: 0,      
    currentPage: 0    
  }

  handlePageClick = (event) => {
    const selectedPage = event.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState({
      currentPage: selectedPage,
      offset: offset
    }, () => {
        this.receivedData()
    })
  }

  handleMoveArchivePage = (result) => async(event) => {
    event.preventDefault()

    if( !cookies.get('udatxu') ) {
      Swal.fire({
        title: 'Info!',
        text: 'Login Expired. Kindly Re-Login',
        icon: 'info',
        confirmButtonText: 'Okay',
        confirmButtonColor: 'steelblue',
        allowOutsideClick: true,
        backdrop: true,
        allowEscapeKey: true,
        allowEnterKey: true            
      }).then(result =>  {
        if(result.isConfirmed) {
          window.location.reload()
        }
      })      
    } else {
      localStorage.setItem('kode_daftar', result.kode_daftar)    
      localStorage.setItem('tgl_daftar', result.tgl_daftar)      
      localStorage.setItem('jenis_bangunan', result.jenis_bangunan)
      localStorage.setItem('keterangan_register_persil', result.keterangan_register_persil)
      localStorage.setItem('alamat_persil', result.alamat_persil)
      localStorage.setItem('kecamatan_persil', result.kecamatan_persil)
      localStorage.setItem('kelurahan_persil', result.kelurahan_persil)
      localStorage.setItem('kode_pos_persil', result.kode_pos_persil)
      localStorage.setItem('kawasan_persil', result.kawasan_persil)
      localStorage.setItem('status_imb', result.status_imb)
      localStorage.setItem('status_kesesuaian_bangunan', result.status_kesesuaian_bangunan)
      localStorage.setItem('status_usulan', result.status_usulan)
      localStorage.setItem('keterangan_verifikasi', result.keterangan_verifikasi)

      window.location.href = '/archivesimak/'+result.kode_daftar
    }    
  }

  downloadSimakCeklis = (result) => (event) => {
    event.preventDefault()

    saveAs('http://localhost:7070/'+result.dok_simak_filePath, result.dok_simak_fileName)
  }

  downloadSP1 = (result) => (event) => {
    event.preventDefault()

    saveAs('http://localhost:7070/'+result.dok_sp1_filePath, result.dok_sp1_fileName)
  }

  resetPage = (event) => {
    event.preventDefault()

    document.querySelector('#search').value = ''
    // window.location.reload()
    this.receivedData()
  }
  
  handleSearch = (event) => {
    event.preventDefault()

    if( !cookies.get('udatxu') ) {
      Swal.fire({
        title: 'Info!',
        text: 'Login Expired. Kindly Re-Login',
        icon: 'info',
        confirmButtonText: 'Okay',
        confirmButtonColor: 'steelblue',
        allowOutsideClick: true,
        backdrop: true,
        allowEscapeKey: true,
        allowEnterKey: true            
      }).then(result =>  {
        if(result.isConfirmed) {
          window.location.reload()
        }
      })  
    } else {
      Axios({
        url: 'http://localhost:7070/fetchTableBangunan',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          "Authorization": 'Bearer ' + cookies.get('udatxu').token        
        },
        data: JSON.stringify({ 
          data_fetch: 'search',
          data_filter: document.querySelector('#search').value
        })                   
      }).then(response => {
        const data = response.data.result
        const slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
        const postData = slice.map((result, index) => {
          return(
            <tr key={ this.state.offset+index+1 }>
              <td className="text-center">{ this.state.offset+index+1 }</td>
              <td className="text-center">{ result.kode_daftar } <hr/> { result.tgl_daftar }</td>            
              <td className="text-center">{ result.alamat_persil + ", Kecamatan " + result.kecamatan_persil + ", Kelurahan " + result.kelurahan_persil + ", " + result.kawasan_persil }</td>
              <td className="text-center">{ result.keterangan_register_persil }</td>
              { (result.dok_simak_fileName === '-' && result.dok_simak_filePath === '') ? 
                <td className="text-center">
                  <button type="button" id="btnDownload" className="btn btn-outline-dark w-100" onClick={ this.handleMoveArchivePage(result) } >
                    <FontAwesomeIcon icon="fa-solid fa-envelopes-bulk" fixedWidth />
                  </button>
                </td> 
                :
                <>
                <td className="text-center">
                  <button type="button" id="btnDownload" className="btn btn-outline-dark w-100" onClick={ this.downloadSimakCeklis(result) } >
                    <FontAwesomeIcon icon="fa-solid fa-envelopes-bulk" fixedWidth /> List
                  </button>
                  <hr />
                  <i>Dicetak Pada : { date.transform(result.tgl_verifikasi, 'DD-MM-YYYY HH:mm:ss', 'DD-MM-YYYY') }</i>
                </td>
                </>
              }
              <td className="text-center">
                <button type="button" id="btnBlast" className="btn btn-outline-dark w-100" onClick={ this.downloadSP1(result) }>
                  <FontAwesomeIcon icon="fa-solid fa-paste" fixedWidth /> SP1
                </button>
                <hr />
                <i>Dicetak Pada : { date.transform(result.tgl_verifikasi, 'DD-MM-YYYY HH:mm:ss', 'DD-MM-YYYY') }</i>
              </td>
              {
                (result.kode_pos_persil === '-') ? 
                <td className="text-center">
                  <i>SP2 Jatuh Pada Tanggal : { date.format(date.addDays(date.parse(result.tgl_verifikasi, "DD-MM-YYYY HH:mm:ss"), 7) , "DD-MM-YYYY") } </i>
                </td>
                :
                <td className="text-center">
                  <button type="button" id="btnBlast" className="btn btn-outline-dark w-100" onClick={ this.downloadSP1(result) }>
                    <FontAwesomeIcon icon="fa-solid fa-paste" fixedWidth /> SP2
                  </button>
                </td>               
              }
              {
                (result.kode_pos_persil === '-') ? 
                <td className="text-center">
                  <i>SP3 Jatuh Pada Tanggal : { date.format(date.addDays(date.parse(result.tgl_verifikasi, "DD-MM-YYYY HH:mm:ss"), 15) , "DD-MM-YYYY") } </i>
                </td>
                :
                <td className="text-center">
                  <button type="button" id="btnBlast" className="btn btn-outline-dark w-100" onClick={ this.downloadSP1(result) }>
                    <FontAwesomeIcon icon="fa-solid fa-paste" fixedWidth /> SP3
                  </button>
                </td>               
              }
            </tr>          
          )
        })      
        this.setState({
          paperNumber: response.data.number,
          pageCount: Math.ceil(data.length / this.state.perPage),
          postData
        })      
      })      
    }    
  }

  receivedData = () => {
    Axios({
      url: 'http://localhost:7070/fetchTableBangunan',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        "Authorization": 'Bearer ' + cookies.get('udatxu').token        
      },
      data: JSON.stringify({ 
        data_role: cookies.get('udatxu').role
      })                   
    }).then(response => {
      const data = response.data.result
      const slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
      const postData = slice.map((result, index) => {
        return(
          <tr key={ this.state.offset+index+1 }>
            <td className="text-center">{ this.state.offset+index+1 }</td>
            <td className="text-center">{ result.kode_daftar } <hr/> { result.tgl_daftar }</td>            
            <td className="text-center">{ result.alamat_persil + ", Kecamatan " + result.kecamatan_persil + ", Kelurahan " + result.kelurahan_persil + ", " + result.kawasan_persil }</td>
            <td className="text-center">{ result.keterangan_register_persil }</td>
            { (result.dok_simak_fileName === '-' && result.dok_simak_filePath === '') ? 
              <td className="text-center">
                <button type="button" id="btnDownload" className="btn btn-outline-dark w-100" onClick={ this.handleMoveArchivePage(result) } >
                  <FontAwesomeIcon icon="fa-solid fa-envelopes-bulk" fixedWidth />
                </button>
              </td> 
              :
              <>
              <td className="text-center">
                <button type="button" id="btnDownload" className="btn btn-outline-dark w-100" onClick={ this.downloadSimakCeklis(result) } >
                  <FontAwesomeIcon icon="fa-solid fa-envelopes-bulk" fixedWidth /> List
                </button>
                <hr />
                <i>Dicetak Pada : { date.transform(result.tgl_verifikasi, 'DD-MM-YYYY HH:mm:ss', 'DD-MM-YYYY') }</i>
              </td>
              </>
            }
            <td className="text-center">
              <button type="button" id="btnBlast" className="btn btn-outline-dark w-100" onClick={ this.downloadSP1(result) }>
                <FontAwesomeIcon icon="fa-solid fa-paste" fixedWidth /> SP1
              </button>
              <hr />
              <i>Dicetak Pada : { date.transform(result.tgl_verifikasi, 'DD-MM-YYYY HH:mm:ss', 'DD-MM-YYYY') }</i>
            </td>
            {
              (result.kode_pos_persil === '-') ? 
              <td className="text-center">
                <i>SP2 Jatuh Pada Tanggal : { date.format(date.addDays(date.parse(result.tgl_verifikasi, "DD-MM-YYYY HH:mm:ss"), 7) , "DD-MM-YYYY") } </i>
              </td>
              :
              <td className="text-center">
                <button type="button" id="btnBlast" className="btn btn-outline-dark w-100" onClick={ this.downloadSP1(result) }>
                  <FontAwesomeIcon icon="fa-solid fa-paste" fixedWidth /> SP2
                </button>
              </td>               
            }
            {
              (result.kode_pos_persil === '-') ? 
              <td className="text-center">
                <i>SP3 Jatuh Pada Tanggal : { date.format(date.addDays(date.parse(result.tgl_verifikasi, "DD-MM-YYYY HH:mm:ss"), 15) , "DD-MM-YYYY") } </i>
              </td>
              :
              <td className="text-center">
                <button type="button" id="btnBlast" className="btn btn-outline-dark w-100" onClick={ this.downloadSP1(result) }>
                  <FontAwesomeIcon icon="fa-solid fa-paste" fixedWidth /> SP3
                </button>
              </td>               
            }
          </tr>          
        )
      })      
      this.setState({
        paperNumber: response.data.number,
        pageCount: Math.ceil(data.length / this.state.perPage),
        postData
      })      
    })    
  }  

  componentDidMount = () => {
    if( !cookies.get('udatxu') ) {
      Swal.fire({
        title: 'Info!',
        text: 'Login Expired. Kindly Re-Login',
        icon: 'info',
        confirmButtonText: 'Okay',
        confirmButtonColor: 'steelblue',
        allowOutsideClick: true,
        backdrop: true,
        allowEscapeKey: true,
        allowEnterKey: true            
      }).then(result =>  {
        if(result.isConfirmed) {
          window.location.reload()
        }
      })  
    } else { 
      this.receivedData()
    }       
  }

  render() {
    return(
      <div className="accordion">
        <div className="accordion-item">
          <h2 className="accordion-header" id="panelsStayOpen-headingOne">
            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
              Arsip Pendataan Simak Pengawasan Bangunan
            </button>
          </h2>
          <div id="panelsStayOpen-collapseOne" className="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingOne">
            <div className="accordion-body">
              <div className="content row g-2">
                <div className="navigation-search col-sm-12 col-md-8 col-lg-8">
                  <input type="text" className="form-control" id="search" placeholder="Cari Persil Berdasarkan Nama Jalan atau Nama Bangunan" />
                </div>
                <div className="navigation-search col-sm-12 col-md-2 col-lg-2">
                  <button type="button" id="btnSearch" className="w-100 btn btn-secondary" onClick={ this.handleSearch }>
                    <FontAwesomeIcon icon="fa-solid fa-magnifying-glass-location" fixedWidth /> Cari
                  </button>
                </div>
                <div className="navigation-search col-sm-12 col-md-2 col-lg-2">
                  <button type="button" id="btnReset" className="w-100 btn btn-outline-secondary" onClick={ this.resetPage }>
                    <FontAwesomeIcon icon="fa-solid fa-square-xmark" fixedWidth /> Reset
                  </button>
                </div>                
                <div className="navigation-upload">
                  <Link to="/uploadexcel">
                    <button type="submit" id="btnSubmit" className="w-100 btn btn-success">
                      <FontAwesomeIcon icon="fa-solid fa-file-import" fixedWidth /> Unggah Tabulasi
                    </button>
                  </Link>
                </div>                  
                <div><hr /></div>
                <div className="navigation-pagination">
                  <ReactPaginate
                      previousLabel={'Prev'}
                      nextLabel={'Next'}
                      pageCount={this.state.pageCount || 0}
                      onPageChange={this.handlePageClick}
                      containerClassName={'pagination'}
                      activeClassName={'active'}
                  />
                </div>                
                <div className="wrapper-table-product table-responsive my-3" style={{ overflow: "auto"}}>
                  <table className="table table-bordered table-hover table-light mb-0">
                    <thead className="thead-light">
                      <tr>
                        <th scope="col" className="text-center" style={{ width: "5%" }}>#</th>
                        <th scope="col" className="text-center" style={{ width: "15%" }}>Kode & Tanggal Daftar</th>                        
                        <th scope="col" className="text-center" style={{ width: "25%" }}>Alamat Persil</th>
                        <th scope="col" className="text-center" style={{ width: "15%" }}>Nama Bangunan</th>
                        <th scope="col" className="text-center" style={{ width: "10%" }}>Ceklis SIMAK</th>
                        <th scope="col" className="text-center" style={{ width: "10%" }}>SK Peringatan Pertama</th>
                        <th scope="col" className="text-center" style={{ width: "10%" }}>SK Peringatan Kedua</th>
                        <th scope="col" className="text-center" style={{ width: "10%" }}>SK Peringatan Ketiga</th>
                      </tr>
                    </thead>
                    <tbody className="table-body" id="table-body">
                      { this.state.postData }
                    </tbody>
                  </table>
                </div>                    
              </div>


            </div>
          </div>                 
        </div>        
      </div>          
    )
  }
}

export default DashArsip