import React from 'react'
import Axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from "react-router-dom"
import Swal from 'sweetalert2'
import Cookies from 'universal-cookie'
// import date from 'date-and-time';

const cookies = new Cookies()
let formData = new FormData()

class UploadExcel extends React.Component {
  state = {
    dok_excel_status: false
  }

  dok_excel_file_change = (event) => {
    event.preventDefault()

    if( event.target.files[0] !== null || event.target.files[0] !== ''  ) {
      formData.append("dok_excel_file", event.target.files[0])
      this.setState({ dok_excel_status: true })      
    }
  }

  onSubmit = async(event) => {
    event.preventDefault()

    if( !cookies.get('udatxu') ) {
      Swal.fire({
        title: 'Info!',
        text: 'Login Expired. Kindly Re-Login',
        icon: 'info',
        confirmButtonText: 'Okay',
        confirmButtonColor: 'steelblue',
        allowOutsideClick: true,
        backdrop: true,
        allowEscapeKey: true,
        allowEnterKey: true            
      }).then(result =>  {
        if(result.isConfirmed) {          
          window.location.reload()
        }
      })  
    } else {
      if( this.state.dok_excel_status === false ) {
        Swal.fire({
          title: 'Error!',
          text: "Wajib Melampirkan Dokumentasi",
          icon: 'error',
          confirmButtonText: 'OKAY',
          confirmButtonColor: 'palevioletred',
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false            
        }).then(result =>  {
          if(result.isConfirmed) {
            window.location.href = '/arsiphomepage'
          }
        })
      } else {
        const datax = await Axios({
          url: 'http://localhost:7070/uploadExcelFiles',
          method: 'POST',
          headers: {
            "Content-Type": "multipart/form-data",
            "Authorization": 'Bearer ' + cookies.get('udatxu').token
          },
          data: formData
        })
        
        /* response from the server */
        if( datax.data.status === 'success' ) {
          Swal.fire({
            title: 'Success!',
            text: datax.data.message,
            icon: 'success',
            confirmButtonText: 'OKAY',
            confirmButtonColor: 'green',
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false            
          }).then(result =>  {
            if(result.isConfirmed) {
              window.location.href = '/arsiphomepage'
            }
          })
        } else {
          Swal.fire({
            title: 'Error!',
            text: datax.data.message,
            icon: 'error',
            confirmButtonText: 'OKAY',
            confirmButtonColor: 'palevioletred',
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false            
          }).then(result =>  {
            if(result.isConfirmed) {
              window.location.href = '/arsiphomepage'
            }
          })
        }        
      }    
  }
}

  render() {
    return(
      <div className="accordion">
        <div className="accordion-item">
          <h2 className="accordion-header" id="panelsStayOpen-headingOne">
            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
              Upload File Tabulasi Excel SIMAK Template
            </button>
          </h2>
          <div id="panelsStayOpen-collapseOne" className="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingOne">
            <div className="accordion-body">
              <form className="row g-3" id="form-upload-excel-simak">
                <div className="form-group dokumentasi">
                  <label htmlFor="formFile" className="form-label">Upload File Excel</label>
                  <input className="form-control" type="file" id="dok_excel_file" name="dok_excel_file" onChange={this.dok_excel_file_change} />
                </div>               
                <div className="button-submit">
                  <button type="submit" className="btn btn-success w-100" onClick={this.onSubmit}>
                      <FontAwesomeIcon icon="fa-solid fa-floppy-disk" fixedWidth /> S I M P A N
                  </button>
                </div>    
                <div className="button-back">
                  <Link to="/arsiphomepage">
                  <button type="button" id="btnBack" className="btn btn-outline-success w-100">
                    <FontAwesomeIcon icon="fa-solid fa-arrow-left" fixedWidth /> K E M B A L I
                    </button>
                  </Link>
                </div>                 
              </form>                           
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default UploadExcel