import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from "react-router-dom"

class DashKlarifikasi extends React.Component {
  render() {
    return(
      <div className="accordion">
        <div className="accordion-item">
          <h2 className="accordion-header" id="panelsStayOpen-headingOne">
            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
              Verifikasi Pendataan Simak Pengawasan Bangunan
            </button>
          </h2>
          <div id="panelsStayOpen-collapseOne" className="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingOne">
            <div className="accordion-body">
              <div className="content row g-2">
                {/* <div className="navigation-add col-sm-12 col-md-4 col-lg-4">
                  <Link to="/addsimak">
                    <button type="submit" id="btnSubmit" className="btn btn-success">
                      <FontAwesomeIcon icon="fa-solid fa-file-circle-plus" fixedWidth /> A D D S I M A K
                    </button>
                  </Link>
                </div> */}
                <div className="navigation-search col-sm-12 col-md-12 col-lg-12">
                  <input type="text" className="form-control" id="search" placeholder="Cari Data SIMAK (coming soon)" />
                </div>  
                <div><hr /></div>
                <div className="wrapper-table-product table-responsive my-3" style={{ overflow: "auto"}}>
                  <table className="table table-bordered table-hover table-light mb-0">
                    <thead className="thead-light">
                      <tr>
                        <th scope="col" className="text-align-center" style={{ width: "5%" }}>#</th>
                        <th scope="col" className="text-center" style={{ width: "20%" }}>Tanggal & Nomor SIMAK</th>
                        <th scope="col" className="text-center" style={{ width: "20%" }}>Tanggal & Surat Peringatan</th>
                        <th scope="col" className="text-center" style={{ width: "25%" }}>Alamat Persil & Keterangan</th>
                        <th scope="col" className="text-center" style={{ width: "10%" }}>Klarifikasi?</th>
                        <th scope="col" className="text-center" style={{ width: "10%" }}>Unduh Surat Peringatan</th>
                        <th scope="col" className="text-center" style={{ width: "10%" }}>Unduh File Dokumentasi</th>
                      </tr>
                    </thead>
                    <tbody className="table-body" id="table-body">
                      <tr>
                        <td className="text-center">1</td>
                        <td className="text-center">
                          10 September 2023
                          <hr/>                          
                          640/1916 PR/436.7.4/2023
                        </td>
                        <td className="text-center">
                          17 September 2023
                          <hr/>                          
                          188.4/00001/436.7.4/2023
                        </td>
                        <td className="text-left" style={{ fontStyle: "italic" }}>
                          Jalan Panglima Sudirman No.1 
                          <hr />
                          Graha Warna Warni
                        </td>
                        <td className="text-center">
                          <Link to="/clarifysimak">
                            <button type="submit" id="btnSubmit" className="btn btn-outline-secondary">
                              <FontAwesomeIcon icon="fa-solid fa-magnifying-glass" fixedWidth />
                            </button>
                          </Link>
                        </td>
                        <td className="text-center">
                          <button type="submit" id="btnDownload" className="btn btn-outline-danger">
                            <FontAwesomeIcon icon="fa-solid fa-file-arrow-down" />
                          </button>
                        </td>
                        <td className="text-center">
                          <button type="submit" id="btnDownload" className="btn btn-outline-primary">
                            <FontAwesomeIcon icon="fa-solid fa-download" />
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>                    
              </div>


            </div>
          </div>                 
        </div>        
      </div>          
    )
  }
}

export default DashKlarifikasi