import React from 'react'
import Axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from "react-router-dom"
import Swal from 'sweetalert2'
import ReactPaginate from 'react-paginate'
import Cookies from 'universal-cookie'
// import { saveAs } from 'file-saver'
// import fileDownload from 'js-file-download'
// import JsFileDownloader from 'js-file-downloader'



import '../../Assets/Styles/DashLapangan.css'
const cookies = new Cookies()

class DashVerifikasi extends React.Component {
  state = {
    dataNumber: 0,
    entries: '',    
    dataMap: [],
    perPage: 5,
    offset: 0,      
    currentPage: 0    
  }

  handlePageClick = (event) => {
    const selectedPage = event.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState({
      currentPage: selectedPage,
      offset: offset
    }, () => {
        this.receivedData()
    })
  }

  handleMoveVerifyPage = (result) => async(event) => {
    event.preventDefault()

    if( !cookies.get('udatxu') ) {
      Swal.fire({
        title: 'Info!',
        text: 'Login Expired. Kindly Re-Login',
        icon: 'info',
        confirmButtonText: 'Okay',
        confirmButtonColor: 'Orange',
        allowOutsideClick: true,
        backdrop: true,
        allowEscapeKey: true,
        allowEnterKey: true            
      }).then(result =>  {
        if(result.isConfirmed) {
          window.location.reload()
        }
      })      
    } else {
      localStorage.setItem('kode_daftar', result.kode_daftar)    
      localStorage.setItem('tgl_daftar', result.tgl_daftar)      
      localStorage.setItem('jenis_bangunan', result.jenis_bangunan)
      localStorage.setItem('keterangan_register_persil', result.keterangan_register_persil)
      localStorage.setItem('alamat_persil', result.alamat_persil)
      localStorage.setItem('kecamatan_persil', result.kecamatan_persil)
      localStorage.setItem('kelurahan_persil', result.kelurahan_persil)
      localStorage.setItem('kode_pos_persil', result.kode_pos_persil)
      localStorage.setItem('kawasan_persil', result.kawasan_persil)

      if( result.jenis_bangunan === 'SLF Bangunan' ){
        window.location.href = '/verifyslf/'+result.kode_daftar
      } else if( result.jenis_bangunan === 'SKRD Bangunan' ) {
        window.location.href = '/verifyimb/'+result.kode_daftar
      } else if( result.jenis_bangunan === 'SKRD Menara' ) {
        window.location.href = '/verifymenara/'+result.kode_daftar
      } else {
        window.location.href = '/verifyreklame/'+result.kode_daftar
      }    
    }    
  }

  alertVerifikasiChecked = (tgl_verifikasi, nama_petugas_verifikasi) => (event) => {
    event.preventDefault()

    if( !cookies.get('udatxu') ) {
      Swal.fire({
        title: 'Info!',
        text: 'Login Expired. Kindly Re-Login',
        icon: 'info',
        confirmButtonText: 'Okay',
        confirmButtonColor: 'Steelblue',
        allowOutsideClick: true,
        backdrop: true,
        allowEscapeKey: true,
        allowEnterKey: true            
      }).then(result =>  {
        if(result.isConfirmed) {
          window.location.reload()
        }
      })  
    } else { 
      Swal.fire({
        title: 'Info!',
        text: 'Data Persil Telah Diverifikasi Pada ' + tgl_verifikasi + " oleh " + nama_petugas_verifikasi,
        icon: 'info',
        confirmButtonText: 'Okay',
        confirmButtonColor: 'Steelblue',
        allowOutsideClick: true,
        backdrop: true,
        allowEscapeKey: true,
        allowEnterKey: true            
      })      
    }    
  }

  handleDownloadSimakFirst = (result) => (event) => {
    event.preventDefault()

    // saveAs('http://localhost:7070/'+result.dok_simak_filePath, result.dok_simak_fileName)
    // fileDownload({ url: 'http://localhost:7070/'+result.dok_simak_filePath, responseType: 'blob' },  result.dok_simak_fileName);

    // Axios({
    //   url: 'http://localhost:7070/'+result.dok_simak_filePath,
    //   method: "GET",
    //   responseType: 'blob',
    // })
    // .then((res) => {
    //   fileDownload(res.data, result.dok_simak_fileName)
    // })

    // new JsFileDownloader({ 
    //   url: 'http://localhost:7070/downloadExcelCeklis/'+result.dok_simak_filePath,
    //   headers: [
    //     { contentType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', Authorization: 'Bearer ' + cookies.get('udatxu').token }
    //   ],      
    //   // contentType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    //   filename: result.dok_simak_fileName,
    //   // process: (progressEvent) => {
    //   //   if (!progressEvent.lengthComputable) return; // guard
    //   //   Math.floor(progressEvent.loaded / progressEvent.total * 100);
    //   // },
    //   contentTypeDetermination: 'header'
    // })      
  }
  
  receivedData = () => {
    Axios({
      url: 'http://localhost:7070/fetchTableBangunan',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        "Authorization": 'Bearer ' + cookies.get('udatxu').token        
      },
      data: JSON.stringify({ 
        data_role: cookies.get('udatxu').role
      })                   
    }).then(response => {
      const data = response.data.result
      const slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
      const postData = slice.map((result, index) => {
        return(
          <tr key={ this.state.offset+index+1 }>
            <td className="text-center">{ this.state.offset+index+1 }</td>
            <td className="text-center">{ result.kode_daftar } <hr/> { result.tgl_daftar }</td>            
            <td className="text-center">{ result.alamat_persil + ", Kecamatan " + result.kecamatan_persil + ", Kelurahan " + result.kelurahan_persil + ", " + result.kawasan_persil + " - " + result.kode_pos_persil } <br/> { " (" + result.keterangan_register_persil + ")" }</td>
            <td className="text-center">{ result.nama_pj_persil + " (" + result.telp_pj_persil + ")" }</td>
            { (result.tgl_verifikasi !== "-" && result.nama_petugas_verifikasi !== '-')  ? 
              <>
                <td className="text-center">
                  <button type="button" id="btnVerifikasiChecked" className="btn btn-outline-dark w-100" onClick={ this.alertVerifikasiChecked(result.tgl_verifikasi,result.nama_petugas_verifikasi ) }>
                    <FontAwesomeIcon icon="fa-solid fa-check-to-slot" fixedWidth /> Verified
                  </button>
                  <hr/>
                  <button type="button" id="btnDownload" className="btn btn-outline-dark w-100" onClick={ this.handleDownloadSimakFirst(result) } >
                    <FontAwesomeIcon icon="fa-solid fa-file-circle-question" fixedWidth /> Simak
                  </button>                  
                </td>                                                        
              </> :  
              <td className="text-center">
                <button type="button" id="btnDownload" className="btn btn-outline-dark w-100" onClick={ this.handleMoveVerifyPage(result) } >
                  <FontAwesomeIcon icon="fa-solid fa-house-circle-check" fixedWidth />
                </button>
              </td>
            }
          </tr>          
        )
      })      
      this.setState({
        paperNumber: response.data.number,
        pageCount: Math.ceil(data.length / this.state.perPage),
        postData
      })      
    })    
  }
  
  componentDidMount = () => {
    if( !cookies.get('udatxu') ) {
      Swal.fire({
        title: 'Info!',
        text: 'Login Expired. Kindly Re-Login',
        icon: 'info',
        confirmButtonText: 'Okay',
        confirmButtonColor: 'Orange',
        allowOutsideClick: true,
        backdrop: true,
        allowEscapeKey: true,
        allowEnterKey: true            
      }).then(result =>  {
        if(result.isConfirmed) {
          window.location.reload()
        }
      })  
    } else { 
      this.receivedData()
    }       
  }  

  render() {
    return(
      <div className="accordion">
        <div className="accordion-item">
          <h2 className="accordion-header" id="panelsStayOpen-headingOne">
            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
              Pendataan Simak Pengawasan Bangunan
            </button>
          </h2>
          <div id="panelsStayOpen-collapseOne" className="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingOne">
            <div className="accordion-body">
              <div className="content row g-2">
                <div className="navigation-search col-sm-12 col-md-10 col-lg-10">
                  <input type="text" className="form-control" id="search" placeholder="Cari Data Persil (coming soon)" />
                </div>
                <div className="navigation-search col-sm-12 col-md-2 col-lg-2">
                  <Link to="/addsimak">
                    <button type="button" id="btnSearch" className="w-100 btn btn-outline-secondary">
                      <FontAwesomeIcon icon="fa-solid fa-magnifying-glass-location" fixedWidth /> Cari
                    </button>
                  </Link>
                </div>                          
                <div><hr /></div>
                <div className="navigation-pagination">
                  <ReactPaginate
                      previousLabel={'Prev'}
                      nextLabel={'Next'}
                      pageCount={this.state.pageCount || 0}
                      onPageChange={this.handlePageClick}
                      containerClassName={'pagination'}
                      activeClassName={'active'}
                  />
                </div>                
                <div className="wrapper-table-product table-responsive my-3" style={{ overflow: "auto"}}>
                  <table className="table table-bordered table-hover table-light mb-0">
                    <thead className="thead-light">
                      <tr>
                        <th scope="col" className="text-center" style={{ width: "5%" }}>#</th>
                        <th scope="col" className="text-center" style={{ width: "30%" }}>Tanggal & Kode Daftar</th>                        
                        <th scope="col" className="text-center" style={{ width: "40%" }}>Alamat Persil & Keterangan</th>
                        <th scope="col" className="text-center" style={{ width: "15%" }}>Penanggung Jawab Persil</th>   
                        <th scope="col" className="text-center" style={{ width: "10%" }}>Verifikasi?</th>
                      </tr>
                    </thead>
                    <tbody className="table-body" id="table-body">
                      { this.state.postData }
                    </tbody>
                  </table>
                </div>                    
              </div>


            </div>
          </div>                 
        </div>        
      </div>          
    )
  }
}

export default DashVerifikasi