import React from 'react'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import Cookies from 'universal-cookie'
import NewLogin from '../Pages/NewLogin'
import Logout from '../Pages/Logout'
import SidebarLapangan from '../Pages/SidebarLapangan'
import SidebarVerifikasi from '../Pages/SidebarVerifikasi'
import DashLapangan from '../Pages/DashLapangan'
import DashVerifikasi from '../Pages/DashVerifikasi'
import DashKlarifikasi from '../Pages/DashKlarifikasi'
import DashArsip from '../Pages/DashArsip'
import Sidebar from '../Pages/Sidebar'
import SidebarArsip from '../Pages/SidebarArsip'
import AddSimak from '../Pages/AddSimak'
import VerifySimak from '../Pages/VerifySimak'
import VerifySLF from '../Pages/VerifySLF'
import VerifyIMB from '../Pages/VerifyIMB'
import ClarifySimak from '../Pages/ClarifySimak'
import ArchiveSimak from '../Pages/AddArchive'
import UploadExcel from '../Pages/UploadExcel'

import '../../Assets/Styles/MainLayout.css';

class MainLayout extends React.Component {
  state = {
    isLogin: false,
  }

  render() {
    const cookies = new Cookies()

    if( !cookies.get('udatxu') ) {
      return(
        <BrowserRouter>
          <div className="container-fluid" id="firstpage">
            <div className="row g-2">
              <Routes>
                <Route exact path="/" element= { <NewLogin /> } />
                <Route path="*" element={<Navigate to={'/'} />} />
              </Routes> 
            </div>
          </div> 
        </BrowserRouter>  
      )
    } else {
      if( cookies.get('udatxu').role === 'lapangan' ) {
        return(
          <BrowserRouter>
            <div className="container-fluid" id="lapangan-homepage">
               <div className="row g-2">
                 <div className="wrapper-sidebar py-2 col-sm-12 col-md-3 col-lg-3">
                   <SidebarLapangan />
                 </div>
                 <div className="wrapper-content py-2 col-sm-12 col-md-9 col-lg-9">
                    <Routes>
                      <Route exact path="/lapanganhomepage" element= { <DashLapangan /> } />
                      <Route exact path="/verifikasihomepage" element= { <DashVerifikasi /> } />
                      <Route exact path="/klarifikasihomepage" element= { <DashKlarifikasi /> } /> 
                      <Route exact path="/arsiphomepage" element= { <DashArsip /> } />
                      <Route exact path="/addsimak" element= { <AddSimak /> } />
                      <Route exact path="/verifyslf/:params" element= { <VerifySLF /> } />
                      <Route exact path="/verifyimb/:params" element= { <VerifyIMB /> } />     
                      <Route exact path="/clarifysimak" element= { <ClarifySimak /> } />
                      <Route exact path="/archivesimak/:params" element= { <ArchiveSimak /> } />
                      <Route exact path="/uploadexcel"  element= { <UploadExcel /> } />                
                      <Route exact path="/logout" element= { <Logout /> } />
                      <Route path="*" element= { <Navigate to={'/lapanganhomepage'} /> } />
                    </Routes>
                 </div>
               </div>
            </div> 
          </BrowserRouter>
        )        
      } else if( cookies.get('udatxu').role === 'verifikasi' ) {
          <BrowserRouter>
            <div className="container-fluid" id="verifikasi-homepage">
              <div className="row g-2">
                <div className="wrapper-sidebar py-2 col-sm-12 col-md-3 col-lg-3">
                  <SidebarVerifikasi />
                </div>
                 <div className="wrapper-content py-2 col-sm-12 col-md-9 col-lg-9">
                    <Routes>
                      <Route exact path="/verifikasihomepage" element= { <DashVerifikasi /> } />
                      <Route exact path="/verifysimak" element= { <VerifySimak /> } />
                      <Route exact path="/logout" element= { <Logout /> } />
                      <Route path="*" element= { <Navigate to={'/verifikasihomepage'} /> } />
                    </Routes>
                 </div>
               </div>
              </div>
          </BrowserRouter>
      } else if( cookies.get('udatxu').role === 'klarifikasi' ) {
          <BrowserRouter>
            <div className="container-fluid" id="klarifikasi-homepage">
              <div className="row g-2">
                <div className="wrapper-sidebar py-2 col-sm-12 col-md-3 col-lg-3">
                  <Sidebar />
                </div>
                 <div className="wrapper-content py-2 col-sm-12 col-md-9 col-lg-9">
                    <Routes>
                      <Route exact path="/klarifikasihomepage" element= { <ClarifySimak /> } />
                      <Route exact path="/logout" element= { <Logout /> } />
                      <Route path="*" element= { <Navigate to={'/klarifikasihomepage'} /> } />
                    </Routes>
                 </div>
              </div>            
            </div>
          </BrowserRouter>
      } else if( cookies.get('udatxu').role === 'arsip' ) {
        return(
          <BrowserRouter>
            <div className="container-fluid" id="arsip-homepage">
              <div className="row g-2">
                <div className="wrapper-sidebar py-2 col-sm-12 col-md-3 col-lg-3">
                  <SidebarArsip />
                </div>
                 <div className="wrapper-content py-2 col-sm-12 col-md-9 col-lg-9">
                    <Routes>
                      <Route exact path="/arsiphomepage" element= { <DashArsip /> } />
                      <Route exact path="/uploadexcel"  element= { <UploadExcel /> } />                
                      <Route exact path="/logout" element= { <Logout /> } />
                      <Route path="*" element= { <Navigate to={'/arsiphomepage'} /> } />
                    </Routes>
                 </div>
              </div>            
            </div>
          </BrowserRouter>          
        )
      }
    }
  }
}

export default MainLayout;