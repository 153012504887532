import React from 'react'
import Axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from "react-router-dom"
import Swal from 'sweetalert2'
import Cookies from 'universal-cookie'
import date from 'date-and-time';

const cookies = new Cookies()

let formData = new FormData()

class VerifySLF extends React.Component {
  state = {
    kode_daftar : localStorage.getItem('kode_daftar'),
    tgl_daftar : localStorage.getItem('tgl_daftar'),    
    jenis_bangunan : localStorage.getItem('jenis_bangunan'),
    alamat_persil : localStorage.getItem('alamat_persil'),    
    kecamatan_persil : localStorage.getItem('kecamatan_persil'),
    kelurahan_persil : localStorage.getItem('kelurahan_persil'),
    kode_pos_persil : localStorage.getItem('kode_pos_persil'),
    kawasan_persil : localStorage.getItem('kawasan_persil'),    
    keterangan_register_persil : localStorage.getItem('keterangan_register_persil'),
    status_slf_imb: "",
    status_kesesuaian_bangunan : "-",
    status_usulan: "",
    keterangan_verifikasi: "",
    dok_verifikasi_status: false
  }

  changeRadiosSLF = (event) => {
    event.preventDefault()

    console.log("IMB Yang Mana ? ", event.target.value)
    this.setState({ status_slf_imb: event.target.value })    
  }

  changeRadiosUsulan = (event) => {
    event.preventDefault()

    console.log("Bangunan Sesuai ? ", event.target.value)
    this.setState({ status_usulan: event.target.value })            
  }

  keteranganVerifikasiChange = (event) => {
    event.preventDefault()

    if( event.target.value !== null || event.target.value !== '' ) {
      this.setState({ keterangan_verifikasi : event.target.value })
    }
  }

  dok_verifikasi_file_change = (event) => {
    event.preventDefault()

    if( event.target.files[0] !== null || event.target.files[0] !== ''  ) {
      formData.append("dok_verifikasi_file", event.target.files[0])
      this.setState({ dok_verifikasi_status: true })      
    }
  }  

  onSubmit = async(event) => {
    event.preventDefault()

    if( !cookies.get('udatxu') ) {
      Swal.fire({
        title: 'Info!',
        text: 'Login Expired. Kindly Re-Login',
        icon: 'info',
        confirmButtonText: 'Okay',
        confirmButtonColor: 'Orange',
        allowOutsideClick: true,
        backdrop: true,
        allowEscapeKey: true,
        allowEnterKey: true            
      }).then(result =>  {
        if(result.isConfirmed) {          
          window.location.reload()
        }
      })  
    } else {
      if( this.state.dok_verifikasi_status === false ) {
        Swal.fire({
          title: 'Error!',
          text: "Wajib Melampirkan Dokumentasi",
          icon: 'error',
          confirmButtonText: 'OKAY',
          confirmButtonColor: 'Orange',
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false            
        }).then(result =>  {
          if(result.isConfirmed) {
            window.location.href = '/'
          }
        })
      } else {
        formData.append("kode_daftar", this.state.kode_daftar)
        formData.append("status_slf_imb", this.state.status_slf_imb)
        formData.append("status_kesesuaian_bangunan", this.state.status_kesesuaian_bangunan)
        formData.append("status_usulan", this.state.status_usulan)
        formData.append("keterangan_verifikasi", this.state.keterangan_verifikasi)
        formData.append("petugas_verifikasi", cookies.get('udatxu').name)
        formData.append("tgl_daftar", localStorage.getItem('tgl_daftar'))    
        formData.append("jenis_bangunan", localStorage.getItem('jenis_bangunan'))
        formData.append("alamat_persil", localStorage.getItem('alamat_persil'))    
        formData.append("kecamatan_persil", localStorage.getItem('kecamatan_persil'))
        formData.append("kelurahan_persil", localStorage.getItem('kelurahan_persil'))
        formData.append("kode_pos_persil", localStorage.getItem('kode_pos_persil'))
        formData.append("kawasan_persil", localStorage.getItem('kawasan_persil'))    
        formData.append("keterangan_register_persil", localStorage.getItem('keterangan_register_persil'))        

        const datax = await Axios({
          url: 'http://localhost:7070/updateVerifikasiBangunan',
          method: 'POST',
          headers: {
            "Content-Type": "multipart/form-data",
            "Authorization": 'Bearer ' + cookies.get('udatxu').token
          },
          data: formData
        })    
        
        /* response from the server */
        if( datax.data.status === 'success' ) {
          Swal.fire({
            title: 'Success!',
            text: datax.data.message,
            icon: 'success',
            confirmButtonText: 'OKAY',
            confirmButtonColor: 'green',
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false            
          }).then(result =>  {
            if(result.isConfirmed) {
              localStorage.removeItem('kode_daftar')
              localStorage.removeItem('tgl_daftar')    
              localStorage.removeItem('jenis_bangunan')
              localStorage.removeItem('alamat_persil')
              localStorage.removeItem('kecamatan_persil')
              localStorage.removeItem('kelurahan_persil')
              localStorage.removeItem('kode_pos_persil')
              localStorage.removeItem('kawasan_persil')            
              localStorage.removeItem('keterangan_register_persil')

              window.location.href = '/verifikasihomepage'
            }
          })
        } else {
          Swal.fire({
            title: 'Error!',
            text: datax.data.message,
            icon: 'error',
            confirmButtonText: 'OKAY',
            confirmButtonColor: 'palevioletred',
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false            
          }).then(result =>  {
            if(result.isConfirmed) {
              window.location.href = '/verifikasihomepage'
            }
          })
        }
      }
    }    
  }

  render() {
    return(
      <div className="accordion">
        <div className="accordion-item">
          <h2 className="accordion-header" id="panelsStayOpen-headingOne">
            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
              Verifikasi Data Simak Pengawasan Bangunan
            </button>
          </h2>
          <div id="panelsStayOpen-collapseOne" className="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingOne">
            <div className="accordion-body row g-2">
              <form className="row g-2" id="form-verifikasi">
                <div className="wrapper-kode-daftar col-sm-12 col-md-6 col-lg-6">
                  <div className="form-floating mb-2" id="kode-daftar">
                    <input type="text" className="form-control" id="kode-daftar" placeholder="abcde" defaultValue={this.state.kode_daftar} readOnly={true} />
                    <label className="form-label">Kode Daftar</label>  
                  </div>
                  <div className="form-floating" id="tanggal-daftar-simak">
                    <input type="text" className="form-control" id="tanggal-daftar-simak" placeholder="abcde" defaultValue={ date.transform(this.state.tgl_daftar, "YYYY-MM-DD", "DD-MM-YYYY") } readOnly={true} />
                    <label className="form-label">Tanggal Daftar Simak</label>  
                  </div>                
                </div>
                <div className="form-floating alamat-persil col-sm-12 col-md-6 col-lg-6">
                  <textarea className="form-control" id="alamat-persil" placeholder="abcde" style={{ height: '125px' }} defaultValue={ this.state.alamat_persil + ", Kecamatan " + this.state.kecamatan_persil + ", Kelurahan " + this.state.kelurahan_persil + ", " + this.state.kawasan_persil + " - " + this.state.kode_pos_persil } readOnly={true}></textarea>
                  <label className="form-label">Alamat Persil</label>
                </div>
                <div className="form-floating keterangan_sidak">
                  <textarea className="form-control" id="keterangan_sidak" placeholder="abcde" style={{ height: '75px' }} defaultValue={this.state.keterangan_register_persil} readOnly={true}></textarea>
                  <label className="form-label">Keterangan Sidak Lapangan</label>
                </div>          
                <div className="baris"> <hr /> </div>
                <div className="wrapper-radio-verifikasi col-sm-12 col-md-3 col-lg-3">
                  <div className="slf" onChange={ this.changeRadiosSLF }>
                    <span className="col-form-label">Status SLF : </span>
                    <div className="form-check">
                      <input className="form-check-input" type="radio" name="slfRadios" id="gridRadios1" value="Memiliki SLF" defaultChecked={this.state.status_slf_imb === 'Memiliki SLF'} />
                      <label className="form-check-label" htmlFor="gridRadios1">
                        Memiliki
                      </label>
                    </div>
                    <div className="form-check">
                      <input className="form-check-input" type="radio" name="slfRadios" id="gridRadios2" value="Tidak Memiliki SLF" defaultChecked={this.state.status_slf_imb === 'Tidak Memiliki SLF'} />
                      <label className="form-check-label" htmlFor="gridRadios2">
                        Tidak Memiliki
                      </label>
                    </div>   
                  </div>
                </div>
                <div className="wrapper-radio-usulan col-sm-12 col-md-9 col-lg-9" onChange={this.changeRadiosUsulan}>
                  <span className="col-form-label">Usulan Tindak Lanjut : </span>
                  <div className="wrapper-pilihan-1">
                    <div className="form-check">
                      <input className="form-check-input" type="radio" name="usulanRadios" id="gridRadios5" value="Mengajukan SLF" defaultChecked={ this.state.status_usulan === 'Mengajukan SLF' } />
                      <label className="form-check-label" htmlFor="gridRadios5">
                        Mengajukan SLF
                      </label>                  
                    </div>
                    <div className="form-check">
                      <input className="form-check-input" type="radio" name="usulanRadios" id="gridRadios6" value="Penghentian Sementara Kegiatan Pemanfaatan Bangunan" defaultChecked={ this.state.status_usulan === 'Penghentian Sementara Kegiatan Pemanfaatan Bangunan' } />
                      <label className="form-check-label" htmlFor="gridRadios6">
                        Penghentian Sementara Kegiatan Pemanfaatan Bangunan
                      </label>
                    </div>          
                    <div className="form-check">
                      <input className="form-check-input" type="radio" name="usulanRadios" id="gridRadios7" value="Penghentian Tetap Kegiatan Pemanfaatan Bangunan" defaultChecked={ this.state.status_usulan === 'Penghentian Tetap Kegiatan Pemanfaatan Bangunan' } />
                      <label className="form-check-label" htmlFor="gridRadios7">
                        Penghentian Tetap Kegiatan Pemanfaatan Bangunan
                      </label>
                    </div>
                  </div>           
                  <div className="wrapper-pilihan-2">
                    <div className="form-check">
                      <input className="form-check-input" type="radio" name="usulanRadios" id="gridRadios9" value="Pencabutan SLF" defaultChecked={ this.state.status_usulan === 'Pencabutan SLF' }/>
                      <label className="form-check-label" htmlFor="gridRadios9">
                        Pencabutan SLF
                      </label>
                    </div>                                                                        
                  </div>
                </div>                
                  <div className="form-floating keterangan">
                    <textarea className="form-control" id="keterangan_verifikasi" name="keterangan_verifikasi" placeholder="abcde" style={{ height: '75px' }} onChange={this.keteranganVerifikasiChange}></textarea>
                    <label className="form-label">Keterangan Hasil Verifikasi</label>
                  </div>                                                   
                  <div className="form-group dokumentasi my-3">
                    <label htmlFor="formFile" className="form-label">Upload Dokumentasi Hasil Verifikasi</label>
                    <input className="form-control" type="file" id="dok_verifikasi_file" name="dok_verifikasi_file" onChange={this.dok_verifikasi_file_change} />
                  </div>                           
              </form>                
              <div className="button-submit">
                <button type="submit" className="btn btn-success w-100" onClick={this.onSubmit}>
                    <FontAwesomeIcon icon="fa-solid fa-floppy-disk" fixedWidth /> S I M P A N
                </button>
              </div>    
              <div className="button-back">
                <Link to="/verifikasihomepage">
                <button type="button" id="btnBack" className="btn btn-outline-success w-100">
                  <FontAwesomeIcon icon="fa-solid fa-arrow-left" fixedWidth /> K E M B A L I
                  </button>
                </Link>
              </div>                                      
            </div>
          </div>                 
        </div>        
      </div>
    )
  }
}

export default VerifySLF