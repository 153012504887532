import React from 'react'
import Axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from "react-router-dom"
import Swal from 'sweetalert2'
import Cookies from 'universal-cookie'

const cookies = new Cookies()

let formData = new FormData()

class VerifyIMB extends React.Component {
  state = {
    kode_daftar : localStorage.getItem('kode_daftar'),
    tgl_daftar : localStorage.getItem('tgl_daftar'),    
    jenis_bangunan : localStorage.getItem('jenis_bangunan'),
    alamat_persil : localStorage.getItem('alamat_persil'),    
    kecamatan_persil : localStorage.getItem('kecamatan_persil'),
    kelurahan_persil : localStorage.getItem('kelurahan_persil'),
    kode_pos_persil : localStorage.getItem('kode_pos_persil'),
    kawasan_persil : localStorage.getItem('kawasan_persil'),    
    keterangan_register_persil : localStorage.getItem('keterangan_register_persil'),
    status_imb: "",
    status_kesesuaian_bangunan : "",
    status_usulan: "",
    keterangan_verifikasi: "",
    dok_verifikasi_status: false
  }

  changeRadiosIMB = (event) => {
    event.preventDefault()

    console.log("IMB Yang Mana ? ", event.target.value)
    this.setState({ status_imb: event.target.value })    
  }

  changeRadiosKesesuaianBangunan = (event) => {
    event.preventDefault()

    console.log("Bangunan Sesuai ? ", event.target.value)
    this.setState({ status_kesesuaian_bangunan: event.target.value })        
  }

  changeRadiosUsulan = (event) => {
    event.preventDefault()

    console.log("Bangunan Sesuai ? ", event.target.value)
    this.setState({ status_usulan: event.target.value })            
  }

  keteranganVerifikasiChange = (event) => {
    event.preventDefault()

    if( event.target.value !== null || event.target.value !== '' ) {
      this.setState({ keterangan_verifikasi : event.target.value })
    }
  }

  dok_verifikasi_file_change = (event) => {
    event.preventDefault()

    if( event.target.files[0] !== null || event.target.files[0] !== ''  ) {
      formData.append("dok_verifikasi_file", event.target.files[0])
      this.setState({ dok_verifikasi_status: true })      
    }
  }  

  onSubmit = async(event) => {
    event.preventDefault()

    if( !cookies.get('udatxu') ) {
      Swal.fire({
        title: 'Info!',
        text: 'Login Expired. Kindly Re-Login',
        icon: 'info',
        confirmButtonText: 'Okay',
        confirmButtonColor: 'Orange',
        allowOutsideClick: true,
        backdrop: true,
        allowEscapeKey: true,
        allowEnterKey: true            
      }).then(result =>  {
        if(result.isConfirmed) {          
          window.location.reload()
        }
      })  
    } else {
      if( this.state.dok_verifikasi_status === false ) {
        Swal.fire({
          title: 'Error!',
          text: "Wajib Melampirkan Dokumentasi",
          icon: 'error',
          confirmButtonText: 'OKAY',
          confirmButtonColor: 'Orange',
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false            
        }).then(result =>  {
          if(result.isConfirmed) {
            window.location.href = '/'
          }
        })
      } else {
        formData.append("kode_daftar", this.state.kode_daftar)
        formData.append("status_imb", this.state.status_imb)
        formData.append("status_kesesuaian_bangunan", this.state.status_kesesuaian_bangunan)
        formData.append("status_usulan", this.state.status_usulan)
        formData.append("keterangan_verifikasi", this.state.keterangan_verifikasi)
        formData.append("tgl_daftar", localStorage.getItem('tgl_daftar'))    
        formData.append("jenis_bangunan", localStorage.getItem('jenis_bangunan'))
        formData.append("alamat_persil", localStorage.getItem('alamat_persil'))    
        formData.append("kecamatan_persil", localStorage.getItem('kecamatan_persil'))
        formData.append("kelurahan_persil", localStorage.getItem('kelurahan_persil'))
        formData.append("kode_pos_persil", localStorage.getItem('kode_pos_persil'))
        formData.append("kawasan_persil", localStorage.getItem('kawasan_persil'))    
        formData.append("keterangan_register_persil", localStorage.getItem('keterangan_register_persil'))        

        const datax = await Axios({
          url: 'http://localhost:7070/updateVerifikasiBangunan',
          method: 'POST',
          headers: {
            "Content-Type": "multipart/form-data",
            "Authorization": 'Bearer ' + cookies.get('udatxu').token
          },
          data: formData
        })    
        
        /* response from the server */
        if( datax.data.status === 'success' ) {
          Swal.fire({
            title: 'Success!',
            text: datax.data.message,
            icon: 'success',
            confirmButtonText: 'OKAY',
            confirmButtonColor: 'green',
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false            
          }).then(result =>  {
            if(result.isConfirmed) {
              localStorage.removeItem('kode_daftar')
              localStorage.removeItem('tgl_daftar')    
              localStorage.removeItem('jenis_bangunan')
              localStorage.removeItem('alamat_persil')
              localStorage.removeItem('kecamatan_persil')
              localStorage.removeItem('kelurahan_persil')
              localStorage.removeItem('kode_pos_persil')
              localStorage.removeItem('kawasan_persil')            
              localStorage.removeItem('keterangan_register_persil')

              window.location.href = '/verifikasihomepage'
            }
          })
        } else {
          Swal.fire({
            title: 'Error!',
            text: datax.data.message,
            icon: 'error',
            confirmButtonText: 'OKAY',
            confirmButtonColor: 'Indianred',
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false            
          }).then(result =>  {
            if(result.isConfirmed) {
              window.location.href = '/verifikasihomepage'
            }
          })
        }
      }
    }    
  }

  render() {
    return(
      <div className="accordion">
        <div className="accordion-item">
          <h2 className="accordion-header" id="panelsStayOpen-headingOne">
            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
              Verifikasi Data Simak Pengawasan Bangunan
            </button>
          </h2>
          <div id="panelsStayOpen-collapseOne" className="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingOne">
            <div className="accordion-body row g-2">
              <form className="row g-2" id="form-verifikasi">
                <div className="wrapper-kode-daftar col-sm-12 col-md-6 col-lg-6">
                  <div className="form-floating mb-2" id="kode-daftar">
                    <input type="text" className="form-control" id="kode-daftar" placeholder="abcde" defaultValue={this.state.kode_daftar} readOnly={true} />
                    <label className="form-label">Kode Daftar</label>  
                  </div>
                  <div className="form-floating" id="tanggal-daftar-simak">
                    <input type="text" className="form-control" id="tanggal-daftar-simak" placeholder="abcde" defaultValue={this.state.tgl_daftar} readOnly={true} />
                    <label className="form-label">Tanggal Daftar Simak</label>  
                  </div>                
                </div>
                <div className="form-floating alamat-persil col-sm-12 col-md-6 col-lg-6">
                  <textarea className="form-control" id="alamat-persil" placeholder="abcde" style={{ height: '125px' }} defaultValue={ this.state.alamat_persil + ", Kecamatan " + this.state.kecamatan_persil + ", Kelurahan " + this.state.kelurahan_persil + ", " + this.state.kawasan_persil + " - " + this.state.kode_pos_persil } readOnly={true}></textarea>
                  <label className="form-label">Alamat Persil</label>
                </div>
                <div className="form-floating keterangan_sidak">
                  <textarea className="form-control" id="keterangan_sidak" placeholder="abcde" style={{ height: '75px' }} defaultValue={this.state.keterangan_register_persil} readOnly={true}></textarea>
                  <label className="form-label">Keterangan Sidak Lapangan</label>
                </div>          
                <div className="baris"> <hr /> </div>
                <div className="wrapper-radio-verifikasi col-sm-12 col-md-4 col-lg-4">
                  <div className="imb" onChange={ this.changeRadiosIMB }>
                    <span className="col-form-label">Status IMB : </span>
                    <div className="form-check">
                      <input className="form-check-input" type="radio" name="imbRadios" id="gridRadios1" value="Memiliki IMB" defaultChecked={this.state.status_imb === 'Memiliki IMB'} />
                      <label className="form-check-label" htmlFor="gridRadios1">
                        Memiliki
                      </label>
                    </div>
                    <div className="form-check">
                      <input className="form-check-input" type="radio" name="imbRadios" id="gridRadios2" value="Tidak Memiliki IMB" defaultChecked={this.state.status_imb === 'Tidak Memiliki IMB'} />
                      <label className="form-check-label" htmlFor="gridRadios2">
                        Tidak Memiliki
                      </label>
                    </div>   
                  </div>
                  <div className="bangunan" onChange={ this.changeRadiosKesesuaianBangunan }>
                    <span className="col-form-label">Kesesuaian Bangunan : </span>
                    <div className="form-check">
                      <input className="form-check-input" type="radio" name="bangunanRadios" id="gridRadios3" value="Bangunan Sesuai" defaultChecked={this.state.status_kesesuaian_bangunan === "Bangunan Sesuai"} />
                      <label className="form-check-label" htmlFor="gridRadios3">
                        Sesuai
                      </label>
                    </div>
                    <div className="form-check">
                      <input className="form-check-input" type="radio" name="bangunanRadios" id="gridRadios4" value="Bangunan Tidak Sesuai" defaultChecked={this.state.status_kesesuaian_bangunan === "Bangunan Tidak Sesuai"} />
                      <label className="form-check-label" htmlFor="gridRadios4">
                        Tidak Sesuai
                      </label>
                    </div>   
                  </div>
                </div>
                <div className="wrapper-dokumentasi-keterangan col-sm-12 col-md-8 col-lg-8">
                  <div className="form-floating keterangan">
                    <textarea className="form-control" id="keterangan_verifikasi" name="keterangan_verifikasi" placeholder="abcde" style={{ height: '75px' }} onChange={this.keteranganVerifikasiChange}></textarea>
                    <label className="form-label">Keterangan Hasil Verifikasi</label>
                  </div>                                                   
                  <div className="form-group dokumentasi mt-2">
                    <label htmlFor="formFile" className="form-label">Upload Dokumentasi Hasil Verifikasi</label>
                    <input className="form-control" type="file" id="dok_verifikasi_file" name="dok_verifikasi_file" onChange={this.dok_verifikasi_file_change} />
                  </div>               
                </div>
                <div className="form-floating usulan" onChange={this.changeRadiosUsulan}>
                  <span className="col-form-label">Usulan Tindak Lanjut : </span>
                  <div className="wrapper-pilihan-1 d-flex flex-row">
                    <div className="form-check col-sm-12 col-md-4 col-lg-4">
                      <input className="form-check-input" type="radio" name="usulanRadios" id="gridRadios5" value="Menghentikan Pembangunan" defaultChecked={ this.state.status_usulan === 'Menghentikan Pembangunan' } />
                      <label className="form-check-label" htmlFor="gridRadios5">
                        Menghentikan Pembangunan
                      </label>                  
                    </div>
                    <div className="form-check col-sm-12 col-md-4 col-lg-4">
                      <input className="form-check-input" type="radio" name="usulanRadios" id="gridRadios6" value="Memiliki IMB" defaultChecked={ this.state.status_usulan === 'Memiliki IMB' } />
                      <label className="form-check-label" htmlFor="gridRadios6">
                        Memiliki IMB
                      </label>
                    </div>          
                    <div className="form-check col-sm-12 col-md-4 col-lg-4">
                      <input className="form-check-input" type="radio" name="usulanRadios" id="gridRadios7" value="Membongkar Sendiri Bangunan" defaultChecked={ this.state.status_usulan === 'Membongkar Sendiri Bangunan' } />
                      <label className="form-check-label" htmlFor="gridRadios7">
                        Membongkar Sendiri Bangunan
                      </label>
                    </div>
                  </div>           
                  <div className="wrapper-pilihan-2 d-flex flex-row">
                    <div className="form-check col-sm-12 col-md-4 col-lg-4">
                      <input className="form-check-input" type="radio" name="usulanRadios" id="gridRadios9" value="Tidak Ditindaklanjuti" defaultChecked={ this.state.status_usulan === 'Tidak Ditindaklanjuti' }/>
                      <label className="form-check-label" htmlFor="gridRadios9">
                        Tidak Ditindaklanjuti
                      </label>
                    </div>                        
                    <div className="form-check col-sm-12 col-md-8 col-lg-8">
                      <input className="form-check-input" type="radio" name="usulanRadios" id="gridRadios8" value="Menyesuaikan Bangunan Sesuai Dengan Ketentuan IMB" defaultChecked={ this.state.status_usulan === 'Menyesuaikan Bangunan Sesuai Dengan Ketentuan IMB' } />
                      <label className="form-check-label" htmlFor="gridRadios8">
                        Menyesuaikan Bangunan Sesuai Dengan Ketentuan IMB atau Memliki IMB Sesuai Pelaksanaan Pembangunan Di Lokasi
                      </label>
                    </div>                                                   
                  </div>
                </div>            
              </form>                
              <div className="button-submit">
                <button type="submit" className="btn btn-success w-100" onClick={this.onSubmit}>
                    <FontAwesomeIcon icon="fa-solid fa-floppy-disk" fixedWidth /> S I M P A N
                </button>
              </div>    
              <div className="button-back">
                <Link to="/verifikasihomepage">
                <button type="button" id="btnBack" className="btn btn-outline-success w-100">
                  <FontAwesomeIcon icon="fa-solid fa-arrow-left" fixedWidth /> K E M B A L I
                  </button>
                </Link>
              </div>                                      
            </div>
          </div>                 
        </div>        
      </div>
    )
  }
}

export default VerifyIMB