import React from 'react';
import {StrictMode} from 'react';
import {createRoot} from 'react-dom/client';

import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";

import Routers from './Components/Layout/MainLayout'

// import { configureStore } from 'redux'
import { configureStore } from '@reduxjs/toolkit'

import { Provider } from 'react-redux'
// const store = configureStore({ reducer: rootReducer })
library.add(fas);
// import StoreData from './Redux/store'


/* State Global */
const initialState = {
  data_uname: null,
  isLogin: false,
  role: null
}

/* Reducer */
const rootReducer = (state = initialState, action) => {
  switch(action.type) {
    case 'UPDATE_USERNAME':
      return{
        ...state,
        data_uname: action.newDataUname
      }
    case 'CHANGE_LOGIN_STATE':
      return{
        ...state,
        isLogin: action.newValue
      }
    case 'GET_ROLE':
      return {
        ...state,
        role: action.newRole
      }

    default:
      return state
  }  
}

/* Store */
const rootStore = configureStore({ reducer: rootReducer })

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
  <StrictMode>
    <Provider store={ rootStore }> 
      <Routers /> 
    </Provider>
  </StrictMode>
);
