import React from 'react'
import Axios from 'axios'
import {connect} from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Swal from 'sweetalert2'
import Cookies from 'universal-cookie'

import '../../Assets/Styles/NewLogin.css';
const cookies = new Cookies()

class NewLogin extends React.Component {
  state = {
    eyeDefault: true,
  }

  clickLogin = async(event) => {
    event.preventDefault()

    if( document.querySelector('#username').value === '' || document.querySelector('#password').value === '' ) {
      Swal.fire({
        title: 'Error!',
        text: 'Kolom Kata Kunci dan Pengguna Tidak Boleh Kosong',
        icon: 'error',
        confirmButtonText: 'OKAY',
        confirmButtonColor: 'orange',            
      })
    } else {
      const datax = await Axios({
        url: 'http://localhost:7070/auth',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        data: JSON.stringify({ 
          username: document.querySelector('#username').value,
          password: document.querySelector('#password').value,
        })                   
      })

      /* data user ditemukan: role peserta active */
      if( datax.data.message === "OK" && datax.data.result.role === "lapangan" ) {
        /* sweetalert2 */
        let timerInterval
        Swal.fire({
          title: 'Success!',
          text: 'Login Succeed',
          icon: 'success',
          confirmButtonText: 'OKAY',
          confirmButtonColor: 'green',
          html: 'Welcome! Will be redirected to Petugas Lapangan Homepage in <b></b> milliseconds.',
            timer: 3000,
            timerProgressBar: true,
            didOpen: () => {
              Swal.showLoading()
              const b = Swal.getHtmlContainer().querySelector('b')
              timerInterval = setInterval(() => {
                b.textContent = Swal.getTimerLeft()
              }, 100)
            },
            willClose: () => {
              clearInterval(timerInterval)
            }
          }).then((result) => {
            if (result.dismiss === Swal.DismissReason.timer) {
              console.log('I was closed by the timer')
            }
            /* set cookies */
            cookies.set('udatxu', JSON.stringify(datax.data.result), { 
              path: '/',
              maxAge: 1800,
            })              
            /* put it in the LocalStorage (cause of using location.href) */
            localStorage.setItem('name', datax.data.result.name)
            /* redirect page to dashboard page */  
            window.location.href = '/lapanganhomepage'
          })
          /* end of sweetalert2 */
      } else if( datax.data.message === "OK" && datax.data.result.role === "verifikasi" ) {
        /* sweetalert2 */
        let timerInterval
        Swal.fire({
          title: 'Success!',
          text: 'Login Succeed',
          icon: 'success',
          confirmButtonText: 'OKAY',
          confirmButtonColor: 'green',
          html: 'Welcome! Will be redirected to Petugas Verifikasi Homepage in <b></b> milliseconds.',
            timer: 3000,
            timerProgressBar: true,
            didOpen: () => {
              Swal.showLoading()
              const b = Swal.getHtmlContainer().querySelector('b')
              timerInterval = setInterval(() => {
                b.textContent = Swal.getTimerLeft()
              }, 100)
            },
            willClose: () => {
              clearInterval(timerInterval)
            }
          }).then((result) => {
            if (result.dismiss === Swal.DismissReason.timer) {
              console.log('I was closed by the timer')
            }
            /* set cookies */
            cookies.set('udatxu', JSON.stringify(datax.data.result), { 
              path: '/',
              maxAge: 1800,
            })              
            /* put it in the LocalStorage (cause of using location.href) */
            localStorage.setItem('name', datax.data.result.name)
            /* redirect page to dashboard page */  
            window.location.href = '/verifikasihomepage'
          })
          /* end of sweetalert2 */
      } else if( datax.data.message === "OK" && datax.data.result.role === "klarifikasi" ) {
        /* sweetalert2 */
        let timerInterval
        Swal.fire({
          title: 'Success!',
          text: 'Login Succeed',
          icon: 'success',
          confirmButtonText: 'OKAY',
          confirmButtonColor: 'green',
          html: 'Welcome! Will be redirected to Petugas Klarifikasi Homepage in <b></b> milliseconds.',
            timer: 3000,
            timerProgressBar: true,
            didOpen: () => {
              Swal.showLoading()
              const b = Swal.getHtmlContainer().querySelector('b')
              timerInterval = setInterval(() => {
                b.textContent = Swal.getTimerLeft()
              }, 100)
            },
            willClose: () => {
              clearInterval(timerInterval)
            }
          }).then((result) => {
            if (result.dismiss === Swal.DismissReason.timer) {
              console.log('I was closed by the timer')
            }
            /* set cookies */
            cookies.set('udatxu', JSON.stringify(datax.data.result), { 
              path: '/',
              maxAge: 1800,
            })              
            /* put it in the LocalStorage (cause of using location.href) */
            localStorage.setItem('name', datax.data.result.name)
            /* redirect page to dashboard page */  
            window.location.href = '/klarifikasihomepage'
          })
          /* end of sweetalert2 */
      } else if( datax.data.message === "OK" && datax.data.result.role === "arsip" ) {
        /* sweetalert2 */
        let timerInterval
        Swal.fire({
          title: 'Success!',
          text: 'Login Succeed',
          icon: 'success',
          confirmButtonText: 'OKAY',
          confirmButtonColor: 'green',
          html: 'Welcome! Will be redirected to Petugas Arsip Homepage in <b></b> milliseconds.',
            timer: 3000,
            timerProgressBar: true,
            didOpen: () => {
              Swal.showLoading()
              const b = Swal.getHtmlContainer().querySelector('b')
              timerInterval = setInterval(() => {
                b.textContent = Swal.getTimerLeft()
              }, 100)
            },
            willClose: () => {
              clearInterval(timerInterval)
            }
          }).then((result) => {
            if (result.dismiss === Swal.DismissReason.timer) {
              console.log('I was closed by the timer')
            }
            /* set cookies */
            cookies.set('udatxu', JSON.stringify(datax.data.result), { 
              path: '/',
              maxAge: 1800,
            })              
            /* put it in the LocalStorage (cause of using location.href) */
            localStorage.setItem('name', datax.data.result.name)
            /* redirect page to dashboard page */  
            window.location.href = '/arsiphomepage'
          })
          /* end of sweetalert2 */
      } else {
        Swal.fire({
          title: 'Error!',
          text: 'Terjadi Kesalahan! Masukkan Pengguna dan Kata Kunci Yang Sesuai!',
          icon: 'error',
          confirmButtonText: 'OKAY',
          confirmButtonColor: 'orange',            
        })      
      }
    }    
  }

  render() {
    return(
      <div className="py-2" id="new-login">
        <div className="card mx-auto" style={{ width: "18rem" }}>
          <img src={require('../../Assets/Images/logo-pemkot13.jpg')} className="img-fluid" alt="avatar" /> 
          <div className="card-body" id="card-body-login">
            <h5 className="card-title" style={{ textAlign: "center" }}>L O G I N - S I M A K</h5>
            <form className="row g-2">
              <div className="form-floating">          
                <input type="text" className="form-control username" id="username" placeholder="Nama Pengguna" />
                <label htmlFor="floatingInput">Nama Pengguna</label>
              </div>
              <div className="form-floating">          
                <input type="password" className="form-control" id="password" placeholder="Kata Kunci" autoComplete="off" style={{ position: "relative"}} />
                <label htmlFor="floatingInput">Kata Kunci</label>
                { this.state.eyeDefault === 'true' ? <FontAwesomeIcon icon="fa-solid fa-eye" id="toggleEye" onClick={ this.showHide } /> : <FontAwesomeIcon icon="fa-solid fa-eye-slash" id="toggleEye" onClick={ this.showHide } /> }
              </div>              
              <div className="button-login py-3">
                <button type="submit" className="btn btn-success w-100" onClick={ this.clickLogin }>
                    <FontAwesomeIcon icon="fa-solid fa-right-to-bracket" fixedWidth /> M A S U K
                </button>
              </div>                                                   
            </form>
          </div>
        </div>        
      </div>
    )
  }

  showHide = (e) => {
    e.preventDefault()
    this.state.eyeDefault === "true" ? this.setState({ eyeDefault: "false" }) : this.setState({ eyeDefault: "true" })
    document.querySelector('#password').type === "password" ? document.querySelector('#password').type = 'text' : document.querySelector('#password').type = 'password'
  }  
}

const mapStateToProps = (state) => {
  return{
    ...state,
    isLogin: state.isLogin
  }
}

const mapDispatchToProps = (dispatch) => {
  return{
    change_status: () => dispatch({ type:'CHANGE_LOGIN_STATE', newValue: true })
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewLogin)