import React from 'react'
import Axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from "react-router-dom"
import Swal from 'sweetalert2'
import Cookies from 'universal-cookie'
import date from 'date-and-time';
const cookies = new Cookies()

/* override date object */
const now = new Date()

let formData = new FormData()
let newValue = ''

let lookup = {
  'Asem Rowo': [
    { id:'1', kawasanText: 'Surabaya Barat', kelurahanText: 'Asem Rowo' },
    { id:'2', kawasanText: 'Surabaya Barat', kelurahanText: 'Genting Kalianak' },
    { id:'3', kawasanText: 'Surabaya Barat', kelurahanText: 'Tambak Sarioso' },
  ],
  'Benowo': [
    { id:'4', kawasanText: 'Surabaya Barat', kelurahanText: 'Kandangan' },
    { id:'5', kawasanText: 'Surabaya Barat', kelurahanText: 'Romokalisari' },
    { id:'6', kawasanText: 'Surabaya Barat', kelurahanText: 'Sememi' },
    { id:'7', kawasanText: 'Surabaya Barat', kelurahanText: 'Tambak Oso Wilangun' },
  ],    
  'Lakarsantri': [
    { id:'8', kawasanText: 'Surabaya Barat', kelurahanText: 'Bangkingan' },
    { id:'9', kawasanText: 'Surabaya Barat', kelurahanText: 'Jeruk' },
    { id:'10', kawasanText: 'Surabaya Barat', kelurahanText: 'Lakarsantri' },
    { id:'11', kawasanText: 'Surabaya Barat', kelurahanText: 'Lidah Kulon' },
    { id:'12', kawasanText: 'Surabaya Barat', kelurahanText: 'Lidah Wetan' },
    { id:'13', kawasanText: 'Surabaya Barat', kelurahanText: 'Sumurwelut' },
  ],      
  'Pakal': [
    { id:'14', kawasanText: 'Surabaya Barat', kelurahanText: 'Babat Jerawat' },
    { id:'15', kawasanText: 'Surabaya Barat', kelurahanText: 'Benowo' },
    { id:'16', kawasanText: 'Surabaya Barat', kelurahanText: 'Pakal' },
    { id:'17', kawasanText: 'Surabaya Barat', kelurahanText: 'Sumber Rejo' },
  ],
  'Sambikerep': [
    { id:'18', kawasanText: 'Surabaya Barat', kelurahanText: 'Beringin' },
    { id:'19', kawasanText: 'Surabaya Barat', kelurahanText: 'Lontar' },
    { id:'20', kawasanText: 'Surabaya Barat', kelurahanText: 'Made' },
    { id:'21', kawasanText: 'Surabaya Barat', kelurahanText: 'Sambikerep' },
  ],    
  'Sukomanunggal': [
    { id:'22', kawasanText: 'Surabaya Barat', kelurahanText: 'Putat Gede' },
    { id:'23', kawasanText: 'Surabaya Barat', kelurahanText: 'Simomulyo' },
    { id:'24', kawasanText: 'Surabaya Barat', kelurahanText: 'Simomulyo Baru' },
    { id:'25', kawasanText: 'Surabaya Barat', kelurahanText: 'Sonokwijenan' },
    { id:'26', kawasanText: 'Surabaya Barat', kelurahanText: 'Sukomanunggal' },
    { id:'27', kawasanText: 'Surabaya Barat', kelurahanText: 'Tanjungsari' },
  ],
  'Tandes': [
    { id:'28', kawasanText: 'Surabaya Barat', kelurahanText: 'Balongsari' },
    { id:'29', kawasanText: 'Surabaya Barat', kelurahanText: 'Banjar Sugihan' },
    { id:'30', kawasanText: 'Surabaya Barat', kelurahanText: 'Karang Poh' },
    { id:'31', kawasanText: 'Surabaya Barat', kelurahanText: 'Manukan Kulon' },
    { id:'32', kawasanText: 'Surabaya Barat', kelurahanText: 'Manukan Wetan' },
    { id:'33', kawasanText: 'Surabaya Barat', kelurahanText: 'Tandes' },
  ],  
  'Bulak': [
    { id:'34', kawasanText: 'Surabaya Utara', kelurahanText: 'Bulak' },
    { id:'35', kawasanText: 'Surabaya Utara', kelurahanText: 'Kedung Cowek' },
    { id:'36', kawasanText: 'Surabaya Utara', kelurahanText: 'Kenjeran' },
    { id:'37', kawasanText: 'Surabaya Utara', kelurahanText: 'Sukolilo Baru' },
  ],
  'Kenjeran': [
    { id:'38', kawasanText: 'Surabaya Utara', kelurahanText: 'Bulak Banteng' },
    { id:'39', kawasanText: 'Surabaya Utara', kelurahanText: 'Sidotopo Wetan' },
    { id:'40', kawasanText: 'Surabaya Utara', kelurahanText: 'Tambak Wedi' },
    { id:'41', kawasanText: 'Surabaya Utara', kelurahanText: 'Tambak Kali Kedinding'},
  ],
  'Krembangan': [
    { id:'42', kawasanText: 'Surabaya Utara', kelurahanText: 'Dupak' },
    { id:'43', kawasanText: 'Surabaya Utara', kelurahanText: 'Kemayoran' },
    { id:'44', kawasanText: 'Surabaya Utara', kelurahanText: 'Krembangan Selatan' },
    { id:'45', kawasanText: 'Surabaya Utara', kelurahanText: 'Morokrembangan'},
    { id:'46', kawasanText: 'Surabaya Utara', kelurahanText: 'Perak Barat'},
  ],
  'Pabean Cantikan': [
    { id:'47', kawasanText: 'Surabaya Utara', kelurahanText: 'Bongkaran' },
    { id:'48', kawasanText: 'Surabaya Utara', kelurahanText: 'Krembangan Utara' },    
    { id:'49', kawasanText: 'Surabaya Utara', kelurahanText: 'Nyamplungan' },
    { id:'50', kawasanText: 'Surabaya Utara', kelurahanText: 'Perak Timur'},
    { id:'51', kawasanText: 'Surabaya Utara', kelurahanText: 'Perak Utara'},
  ],  
  'Semampir': [
    { id:'52', kawasanText: 'Surabaya Utara', kelurahanText: 'Ampel' },
    { id:'53', kawasanText: 'Surabaya Utara', kelurahanText: 'Pegirian' },    
    { id:'54', kawasanText: 'Surabaya Utara', kelurahanText: 'Sidotopo' },
    { id:'55', kawasanText: 'Surabaya Utara', kelurahanText: 'Ujung'},
    { id:'56', kawasanText: 'Surabaya Utara', kelurahanText: 'Wonokusumo'},
  ],    
  'Gubeng': [
    { id:'57', kawasanText: 'Surabaya Timur', kelurahanText: 'Airlangga' },
    { id:'58', kawasanText: 'Surabaya Timur', kelurahanText: 'Baratajaya' },
    { id:'59', kawasanText: 'Surabaya Timur', kelurahanText: 'Gubeng' },
    { id:'60', kawasanText: 'Surabaya Timur', kelurahanText: 'Kertajaya' },
    { id:'61', kawasanText: 'Surabaya Timur', kelurahanText: 'Mojo' },
    { id:'62', kawasanText: 'Surabaya Timur', kelurahanText: 'Pucang Sewu' },
  ],
  'Gunung Anyar': [
    { id:'63', kawasanText: 'Surabaya Timur', kelurahanText: 'Gunung Anyar' },
    { id:'64', kawasanText: 'Surabaya Timur', kelurahanText: 'Gunung Anyar Tambak' },
    { id:'65', kawasanText: 'Surabaya Timur', kelurahanText: 'Rungkut Menanggal' },
    { id:'66', kawasanText: 'Surabaya Timur', kelurahanText: 'Rungkut Tengah' },
  ],
  'Mulyorejo': [
    { id:'67', kawasanText: 'Surabaya Timur', kelurahanText: 'Dukuh Sutorejo' },
    { id:'68', kawasanText: 'Surabaya Timur', kelurahanText: 'Kalijudan' },
    { id:'69', kawasanText: 'Surabaya Timur', kelurahanText: 'Kalisari' },
    { id:'70', kawasanText: 'Surabaya Timur', kelurahanText: 'Kejawan Putih Tambak' },
    { id:'71', kawasanText: 'Surabaya Timur', kelurahanText: 'Manyar Sabrangan' },
    { id:'72', kawasanText: 'Surabaya Timur', kelurahanText: 'Mulyorejo' },
  ],
  'Rungkut': [
    { id:'73', kawasanText: 'Surabaya Timur', kelurahanText: 'Kali Rungkut' },
    { id:'74', kawasanText: 'Surabaya Timur', kelurahanText: 'Kedung Baruk' },
    { id:'75', kawasanText: 'Surabaya Timur', kelurahanText: 'Medokan Ayu' },
    { id:'76', kawasanText: 'Surabaya Timur', kelurahanText: 'Penjaringansari' },
    { id:'77', kawasanText: 'Surabaya Timur', kelurahanText: 'Rungkut Kidul' },
    { id:'78', kawasanText: 'Surabaya Timur', kelurahanText: 'Wonorejo Rungkut' },
  ],
  'Sukolilo': [
    { id:'79', kawasanText: 'Surabaya Timur', kelurahanText: 'Gebang Putih' },
    { id:'80', kawasanText: 'Surabaya Timur', kelurahanText: 'Keputih' },
    { id:'81', kawasanText: 'Surabaya Timur', kelurahanText: 'Klampis Ngasem' },
    { id:'82', kawasanText: 'Surabaya Timur', kelurahanText: 'Medokan Semampir' },
    { id:'83', kawasanText: 'Surabaya Timur', kelurahanText: 'Menur Pumpungan' },
    { id:'84', kawasanText: 'Surabaya Timur', kelurahanText: 'Nginden Jangkungan' },
    { id:'85', kawasanText: 'Surabaya Timur', kelurahanText: 'Semolowawru' },
  ],
  'Tambaksari': [
    { id:'86', kawasanText: 'Surabaya Timur', kelurahanText: 'Dukuh Setro' },
    { id:'87', kawasanText: 'Surabaya Timur', kelurahanText: 'Gading' },
    { id:'88', kawasanText: 'Surabaya Timur', kelurahanText: 'Kapasmadya Baru' },
    { id:'89', kawasanText: 'Surabaya Timur', kelurahanText: 'Pacarkeling' },
    { id:'90', kawasanText: 'Surabaya Timur', kelurahanText: 'Pacarkembang' },
    { id:'91', kawasanText: 'Surabaya Timur', kelurahanText: 'Ploso' },
    { id:'92', kawasanText: 'Surabaya Timur', kelurahanText: 'Rangkah' },
    { id:'93', kawasanText: 'Surabaya Timur', kelurahanText: 'Tambaksari' },
  ],
  'Tenggilis Mejoyo': [
    { id:'94', kawasanText: 'Surabaya Timur', kelurahanText: 'Kendangsari' },
    { id:'95', kawasanText: 'Surabaya Timur', kelurahanText: 'Kutisari' },
    { id:'96', kawasanText: 'Surabaya Timur', kelurahanText: 'Panjang Jiwo' },
    { id:'97', kawasanText: 'Surabaya Timur', kelurahanText: 'Penjaringansari' },
    { id:'98', kawasanText: 'Surabaya Timur', kelurahanText: 'Tenggilis Mejoyo' },
  ],
  'Dukuh Pakis': [
    { id:'99', kawasanText: 'Surabaya Selatan', kelurahanText: 'Dukuh Kupang' },
    { id:'100', kawasanText: 'Surabaya Selatan', kelurahanText: 'Dukuh Pakis' },
    { id:'101', kawasanText: 'Surabaya Selatan', kelurahanText: 'Gunungsari' },
    { id:'102', kawasanText: 'Surabaya Selatan', kelurahanText: 'Pradah Kalikendal' },
  ],
  'Gayungan': [
    { id:'103', kawasanText: 'Surabaya Selatan', kelurahanText: 'Dukuh Menanggal' },
    { id:'104', kawasanText: 'Surabaya Selatan', kelurahanText: 'Gayungan' },
    { id:'105', kawasanText: 'Surabaya Selatan', kelurahanText: 'Ketintang' },
    { id:'106', kawasanText: 'Surabaya Selatan', kelurahanText: 'Menanggal' },
  ],
  'Jambangan': [
    { id:'107', kawasanText: 'Surabaya Selatan', kelurahanText: 'Jambangan' },
    { id:'108', kawasanText: 'Surabaya Selatan', kelurahanText: 'Karah' },
    { id:'109', kawasanText: 'Surabaya Selatan', kelurahanText: 'Kebonsari' },
    { id:'110', kawasanText: 'Surabaya Selatan', kelurahanText: 'Pagesangan' },
  ],
  'Karang Pilang': [
    { id:'111', kawasanText: 'Surabaya Selatan', kelurahanText: 'Karang Pilang' },
    { id:'112', kawasanText: 'Surabaya Selatan', kelurahanText: 'Kebraon' },
    { id:'113', kawasanText: 'Surabaya Selatan', kelurahanText: 'Kedurus' },
    { id:'114', kawasanText: 'Surabaya Selatan', kelurahanText: 'Waru Gunung' },
  ],
  'Sawahan': [
    { id:'115', kawasanText: 'Surabaya Selatan', kelurahanText: 'Banyu Urip' },
    { id:'116', kawasanText: 'Surabaya Selatan', kelurahanText: 'Kupang Krajan' },
    { id:'117', kawasanText: 'Surabaya Selatan', kelurahanText: 'Pakis' },
    { id:'118', kawasanText: 'Surabaya Selatan', kelurahanText: 'Petemon' },
    { id:'119', kawasanText: 'Surabaya Selatan', kelurahanText: 'Putat Jaya' },
    { id:'120', kawasanText: 'Surabaya Selatan', kelurahanText: 'Sawahan' },    
  ],
  'Wiyung': [
    { id:'121', kawasanText: 'Surabaya Selatan', kelurahanText: 'Babatan' },
    { id:'122', kawasanText: 'Surabaya Selatan', kelurahanText: 'Balas Klumprik' },
    { id:'123', kawasanText: 'Surabaya Selatan', kelurahanText: 'Jajar Tunggal' },
    { id:'124', kawasanText: 'Surabaya Selatan', kelurahanText: 'Wiyung' },    
  ],
  'Wonocolo': [
    { id:'125', kawasanText: 'Surabaya Selatan', kelurahanText: 'Bendul Merisi' },
    { id:'126', kawasanText: 'Surabaya Selatan', kelurahanText: 'Jemur Wonosari' },
    { id:'127', kawasanText: 'Surabaya Selatan', kelurahanText: 'Margorejo' },
    { id:'128', kawasanText: 'Surabaya Selatan', kelurahanText: 'Sidosermo' },    
    { id:'129', kawasanText: 'Surabaya Selatan', kelurahanText: 'Siwalankerto' },    
  ],
  'Wonokromo': [
    { id:'130', kawasanText: 'Surabaya Selatan', kelurahanText: 'Darmo' },
    { id:'131', kawasanText: 'Surabaya Selatan', kelurahanText: 'Jagir' },
    { id:'132', kawasanText: 'Surabaya Selatan', kelurahanText: 'Ngagel' },
    { id:'133', kawasanText: 'Surabaya Selatan', kelurahanText: 'Ngagel Rejo' },    
    { id:'134', kawasanText: 'Surabaya Selatan', kelurahanText: 'Sawunggaling' },    
    { id:'135', kawasanText: 'Surabaya Selatan', kelurahanText: 'Wonokromo' },    
  ],
  'Bubutan': [
    { id:'136', kawasanText: 'Surabaya Pusat', kelurahanText: 'Alun-Alun Contong' },
    { id:'137', kawasanText: 'Surabaya Pusat', kelurahanText: 'Bubutan' },
    { id:'138', kawasanText: 'Surabaya Pusat', kelurahanText: 'Gundih' },
    { id:'139', kawasanText: 'Surabaya Pusat', kelurahanText: 'Jepara' },
    { id:'140', kawasanText: 'Surabaya Pusat', kelurahanText: 'Tembok Dukuh' }
  ],
  'Genteng': [
    { id:'141', kawasanText: 'Surabaya Pusat', kelurahanText: 'Embong Kaliasin' },
    { id:'142', kawasanText: 'Surabaya Pusat', kelurahanText: 'Genteng' },
    { id:'143', kawasanText: 'Surabaya Pusat', kelurahanText: 'Kapasari' },
    { id:'144', kawasanText: 'Surabaya Pusat', kelurahanText: 'Ketabang' },
    { id:'145', kawasanText: 'Surabaya Pusat', kelurahanText: 'Peneleh' }
  ],
  'Simokerto': [
    { id:'146', kawasanText: 'Surabaya Pusat', kelurahanText: 'Kapasan' },
    { id:'147', kawasanText: 'Surabaya Pusat', kelurahanText: 'Sidodadi' },
    { id:'148', kawasanText: 'Surabaya Pusat', kelurahanText: 'Simokerto' },
    { id:'149', kawasanText: 'Surabaya Pusat', kelurahanText: 'Simolawang' },
    { id:'150', kawasanText: 'Surabaya Pusat', kelurahanText: 'Tambakrejo' }
  ],
  'Tegalsari': [
    { id:'151', kawasanText: 'Surabaya Pusat', kelurahanText: 'Dr.Soetomo' },
    { id:'152', kawasanText: 'Surabaya Pusat', kelurahanText: 'Kedungdoro' },
    { id:'153', kawasanText: 'Surabaya Pusat', kelurahanText: 'Keputran' },
    { id:'154', kawasanText: 'Surabaya Pusat', kelurahanText: 'Tegalsari' },
    { id:'155', kawasanText: 'Surabaya Pusat', kelurahanText: 'Wonorejo Tegalsari' }
  ]
}

class AddSimak extends React.Component {
  state = {
    tgl_register: date.format(now, 'YYYY-MM-DD'),
    nama_pj_persil: '',
    no_telp_pj_persil: '',
    alamat_persil:'',
    jenis_bangunan: "SKRD Bangunan",
    kecamatan:'',
    kelurahan:'',
    kawasan: '',
    kode_pos: '',    
    keterangan: '',
    dok_lapangan_status: false,
    dataValue: "Asem Rowo"
  }

  handleDateChange = (event) => {
    event.preventDefault()

    if( event.target.value !== this.state.value ) {
      this.setState({ tgl_register: event.target.value })
    }
  }

  nama_pj_persil_change = (event) => {
    event.preventDefault()

    if( event.target.value !== null || event.target.value !== '' ) {
      this.setState({ nama_pj_persil: event.target.value })
    }
  }  

  no_telp_pj_persil_change = (event) => {
    event.preventDefault()

    if( event.target.value !== null || event.target.value !== '' ) {
      this.setState({ no_telp_pj_persil: event.target.value })
    }
  }    

  alamat_persil_change = (event) => {
    event.preventDefault()

    if( event.target.value !== null || event.target.value !== '' ) {
      this.setState({ alamat_persil: event.target.value })
    }
  }     
  
  kode_pos_change = (event) => {
    event.preventDefault()

    if( event.target.value !== null || event.target.value !== '' ) {
      this.setState({ kode_pos: event.target.value })
    }
  }       

  keterangan_change = (event) => {
    event.preventDefault()

    if( event.target.value !== null || event.target.value !== '' ) {
      this.setState({ keterangan: event.target.value })
    }
  }       

  dok_lapangan_file_change = (event) => {
    event.preventDefault()

    if( event.target.files[0] !== null || event.target.files[0] !== ''  ) {
      formData.append("dok_lapangan_file", event.target.files[0])
      this.setState({ dok_lapangan_status: true })      
    }
  }

  handleRadiosChange = (event) => {
    event.preventDefault()

    this.setState({ jenis_bangunan: event.target.value })
  }

  handleKecamatanChange = ({ target: { value } }) => {
    this.setState({ kecamatan: value })
    this.setState({ dataValue: value });
  }

  handleKelurahanChange = ({ target: { value } }) => {
    newValue = value.split(',')
    this.setState({ kelurahan: newValue[0] })
    this.setState({ kawasan: newValue[1] })
  }  

  componentDidUpdate = () => {
    document.querySelector('#kawasan').value = this.state.kawasan
  }

  onSubmit = async(event) => {
    event.preventDefault()    

    if( !cookies.get('udatxu') ) {
      Swal.fire({
        title: 'Info!',
        text: 'Login Expired. Kindly Re-Login',
        icon: 'info',
        confirmButtonText: 'Okay',
        confirmButtonColor: 'Orange',
        allowOutsideClick: true,
        backdrop: true,
        allowEscapeKey: true,
        allowEnterKey: true            
      }).then(result =>  {
        if(result.isConfirmed) {          
          window.location.reload()
        }
      })  
    } else {
      if( this.state.dok_lapangan_status === false ) {
        Swal.fire({
          title: 'Error!',
          text: "Wajib Melampirkan Dokumentasi",
          icon: 'error',
          confirmButtonText: 'OKAY',
          confirmButtonColor: 'Orange',
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false            
        }).then(result =>  {
          if(result.isConfirmed) {
            window.location.reload()
          }
        })
      } else {
        /* send to the server */
        formData.append("name", this.name.value)
        formData.append("tgl_register", this.state.tgl_register)
        formData.append("nama_pj_persil", this.state.nama_pj_persil)
        formData.append("no_telp_pj_persil", this.state.no_telp_pj_persil)
        formData.append("alamat_persil", this.state.alamat_persil)
        formData.append("jenis_bangunan", this.state.jenis_bangunan)
        formData.append("kecamatan", this.state.kecamatan)
        formData.append("kelurahan", this.state.kelurahan)
        formData.append("kawasan", this.state.kawasan)
        formData.append("kode_pos", this.state.kode_pos)
        formData.append("keterangan", this.state.keterangan)

        const datax = await Axios({
          url: 'http://localhost:7070/saveBangunan',
          method: 'POST',
          headers: {
            "Content-Type": "multipart/form-data",
            "Authorization": 'Bearer ' + cookies.get('udatxu').token
          },
          data: formData
        })

        /* response from the server */
        if( datax.data.status === 'success' ) {
          Swal.fire({
            title: 'Success!',
            text: datax.data.message,
            icon: 'success',
            confirmButtonText: 'OKAY',
            confirmButtonColor: 'green',
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false            
          }).then(result =>  {
            if(result.isConfirmed) {
              window.location.href = '/'
            }
          })
        } else {
          Swal.fire({
            title: 'Error!',
            text: datax.data.message,
            icon: 'error',
            confirmButtonText: 'OKAY',
            confirmButtonColor: 'Orange',
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false            
          }).then(result =>  {
            if(result.isConfirmed) {
              window.location.href = '/'
            }
          })
        } 
      }        
    }    
  }

  render() {
    let { dataValue } = this.state
    let options = lookup[dataValue]

    return(
      <div className="accordion">
        <div className="accordion-item">
          <h2 className="accordion-header" id="panelsStayOpen-headingOne">
            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
              Pendataan Simak Pengawasan Bangunan
            </button>
          </h2>
          <div id="panelsStayOpen-collapseOne" className="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingOne">
            <div className="accordion-body">
              <form className="row g-2" id="form-pendataan-bangunan">
                <input type="hidden" name="name" id="name" defaultValue={cookies.get('udatxu').name} ref={(input) => { this.name = input }} />
                <div className="form-floating tgl-register">
                  <input type="date" className="form-control" id="tgl-register" placeholder="00/00/0000" defaultValue={this.state.tgl_register} onChange={this.handleDateChange} autoFocus required/>
                  <label className="form-label">Tanggal Register</label>
                </div>                
                <div className="form-floating detil-nama-pemilik-persil col-sm-12 col-md-6 col-lg-6">
                  <input type="text" className="form-control" id="nama_pj_persil" name="nama_pj_persil" placeholder="abcde" value={this.state.nama_pj_persil} onChange={this.nama_pj_persil_change} required />
                  <label htmlFor="floatingInput">Nama Pemilik Persil</label>
                </div>
                <div className="form-floating detil-no-telp-pemilik-persil col-sm-12 col-md-6 col-lg-6">
                  <input type="text" className="form-control" id="no_telp_pj_persil" name="no_telp_pj_persil" placeholder="0123456789" value={this.state.no_telp_pj_persil} onChange={this.no_telp_pj_persil_change} required />
                  <label htmlFor="floatingInput">Nomor Telepon Pemilik Persil</label>
                </div>
                <div className="form-floating alamat-persil">
                  <textarea className="form-control" id="alamat_persil" name="alamat_persil" placeholder="abcde" style={{ height: '75px' }} value={this.state.alamat_persil} onChange={this.alamat_persil_change} required></textarea>
                  <label className="form-label">Alamat Persil</label>
                </div>
                <div className="jenis-bangunan">
                  <span className="col-form-label">Jenis Bangunan : </span>
                  <div className="wrapper-radios" onChange={this.handleRadiosChange}>
                    <div className="form-check form-check-inline">
                      <input className="form-check-input" type="radio" name="jenis-bangunan" id="skrdbangunan" value="SKRD Bangunan" defaultChecked={this.state.bangunan === 'SKRD Bangunan'} />
                      <label className="form-check-label" htmlFor="skrdbangunan">
                        SKRD Bangunan
                      </label>                      
                    </div>                 
                    <div className="form-check form-check-inline">
                      <input className="form-check-input" type="radio" name="jenis-bangunan" id="menara" value="SKRD Menara" defaultChecked={this.state.bangunan === 'SKRD Menara'}  />
                      <label className="form-check-label" htmlFor="menara">
                        SKRD Menara atau Tower
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input className="form-check-input" type="radio" name="jenis-bangunan" id="slfbangunan" value="SLF Bangunan" defaultChecked={this.state.bangunan === 'SLF Bangunan'}  />
                      <label className="form-check-label" htmlFor="slfbangunan">
                        SLF Bangunan Gedung
                      </label>
                    </div>                                        
                    <div className="form-check form-check-inline">
                      <input className="form-check-input" type="radio" name="jenis-bangunan" id="reklame" value="SKRD Reklame" defaultChecked={this.state.bangunan === 'SKRD Reklame'}  />
                      <label className="form-check-label" htmlFor="reklame">
                        SKRD Reklame
                      </label>
                    </div>                            
                  </div>
                </div>
                <div className="form-floating kecamatan-persil col-sm-12 col-md-6 col-lg-6">
                  <select className="form-control" id="kecamatan-select" defaultValue={""} onChange={this.handleKecamatanChange}>
                    <option value="" disabled hidden> - Pilih Kecamatan - </option>
                    <option value="Asem Rowo">Asem Rowo</option>
                    <option value="Benowo">Benowo</option>
                    <option value="Lakarsantri">Lakarsantri</option>
                    <option value="Pakal">Pakal</option>
                    <option value="Sambikerep">Sambikerep</option>
                    <option value="Sukomanunggal">Sukomanunggal</option>
                    <option value="Tandes">Tandes</option>
                    <option value="Bubutan">Bubutan</option>
                    <option value="Genteng">Genteng</option>
                    <option value="Simokerto">Simokerto</option>
                    <option value="Tegalsari">Tegalsari</option>
                    <option value="Bulak">Bulak</option>
                    <option value="Kenjeran">Kenjeran</option>
                    <option value="Krembangan">Krembangan</option>
                    <option value="Pabean Cantikan">Pabean Cantikan</option>
                    <option value="Semampir">Semampir</option>
                    <option value="Gubeng">Gubeng</option>
                    <option value="Gunung Anyar">Gunung Anyar</option>
                    <option value="Mulyorejo">Mulyorejo</option>
                    <option value="Rungkut">Rungkut</option>
                    <option value="Sukolilo">Sukolilo</option>
                    <option value="Tambaksari">Tambaksari</option>
                    <option value="Tenggilis Mejoyo">Tenggilis Mejoyo</option>
                    <option value="Dukuh Pakis">Dukuh Pakis</option>
                    <option value="Gayungan">Gayungan</option>
                    <option value="Jambangan">Jambangan</option>
                    <option value="Karang Pilang">Karang Pilang</option>
                    <option value="Sawahan">Sawahan</option>  
                    <option value="Wiyung">Wiyung</option>
                    <option value="Wonocolo">Wonocolo</option>  
                    <option value="Wonokromo">Wonokromo</option>
                  </select>
                  <label htmlFor="floatingSelect">Kecamatan</label>
                </div>
                <div className="form-floating kawasan col-sm-12 col-md-6 col-lg-6">
                  <input type="text" className="form-control" id="kawasan" name="kawasan" placeholder="Kawasan" disabled={true} value={this.state.kawasan === '' ? '' : this.state.kawasan} />
                  <label htmlFor="floatingInput">Kawasan</label>
                </div>                
                <div className="form-floating kelurahan-persil col-sm-12 col-md-6 col-lg-6">
                  <select className="form-select" id="kelurahan-select" defaultValue={""} onChange={this.handleKelurahanChange}>
                    <option value="" disabled hidden> - Pilih Kelurahan - </option>
                  { options !== null ? options.map((nilaiVal, key) => <option key={key} value={[nilaiVal.kelurahanText, nilaiVal.kawasanText]} name="kelurahan" id="kelurahan">{nilaiVal.kelurahanText}</option>) : console.log("maps null") }
                  </select>
                  <label htmlFor="floatingSelect">Kelurahan</label>
                </div>
                <div className="form-floating kode-pos col-sm-12 col-md-6 col-lg-6">
                  <input type="text" className="form-control" id="kode_pos" name="kode_pos" placeholder="abcde" value={this.state.kode_pos} onChange={this.kode_pos_change} required />
                  <label className="form-label">Kode Pos</label>
                </div>  
                <div className="form-floating keterangan">
                  <textarea className="form-control" id="keterangan" name="keterangan" placeholder="abcde" style={{ height: '75px' }} value={this.state.keterangan} onChange={this.keterangan_change} required></textarea>
                  <label className="form-label">Keterangan</label>
                </div>                                              
                <div className="form-group dokumentasi">
                  <label htmlFor="formFile" className="form-label">Upload Dokumentasi</label>
                  <input className="form-control" type="file" id="dok_lapangan_file" name="dok_lapangan_file" onChange={this.dok_lapangan_file_change} required />
                </div> 
              </form>  
              <div className="button-submit py-2 mt-2">
                <button type="submit" id="btnSubmit" className="btn btn-success w-100" onClick={ this.onSubmit }>
                    <FontAwesomeIcon icon="fa-solid fa-floppy-disk" fixedWidth /> S I M P A N
                </button>
              </div>     
              <div className="button-back">
                <Link to="/dashlapangan">
                <button type="button" id="btnBack" className="btn btn-outline-success w-100">
                  <FontAwesomeIcon icon="fa-solid fa-arrow-left" fixedWidth /> K E M B A L I
                  </button>
                </Link>
              </div>                                                  
            </div>
          </div>                 
        </div>        
      </div>
    )
  }
}

export default AddSimak