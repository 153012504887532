import React from 'react'
import Axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from "react-router-dom"
import Swal from 'sweetalert2'
import ReactPaginate from 'react-paginate'
import Cookies from 'universal-cookie'
import { saveAs } from 'file-saver'

import '../../Assets/Styles/DashLapangan.css'
const cookies = new Cookies()

class DashLapangan extends React.Component {
  state = {
    dataNumber: 0,
    entries: '',    
    dataMap: [],
    perPage: 5,
    offset: 0,      
    currentPage: 0    
  }
  
  handleDownload = (filePath, fileName) => (event) => {
    event.preventDefault()

    if( !cookies.get('udatxu') ) {
      Swal.fire({
        title: 'Info!',
        text: 'Login Expired. Kindly Re-Login',
        icon: 'info',
        confirmButtonText: 'Okay',
        confirmButtonColor: 'Orange',
        allowOutsideClick: true,
        backdrop: true,
        allowEscapeKey: true,
        allowEnterKey: true            
      }).then(result =>  {
        if(result.isConfirmed) {
          window.location.reload()
        }
      })      
    } else {    
      saveAs('http://localhost:7070/'+filePath, fileName)
    }    
  }

  handlePageClick = (event) => {
    const selectedPage = event.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState({
      currentPage: selectedPage,
      offset: offset
    }, () => {
        this.receivedData()
    })
  }

  receivedData = () => {
    Axios({
      url: 'http://localhost:7070/fetchTableBangunan',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        "Authorization": 'Bearer ' + cookies.get('udatxu').token        
      },
      data: JSON.stringify({ 
        data_role: cookies.get('udatxu').role
      })                   
    }).then(response => {
      const data = response.data.result
      const slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
      const postData = slice.map((result, index) => {
        return(
          <tr key={ this.state.offset+index+1 }>
            <td className="text-center">{ this.state.offset+index+1 }</td>
            <td className="text-center">{ result.kode_daftar } <hr/> { result.tgl_daftar }</td>
            <td className="text-center">{ result.alamat_persil + ", Kecamatan " + result.kecamatan_persil + ", Kelurahan " + result.kelurahan_persil + ", " + result.kawasan_persil + " - " + result.kode_pos_persil } <br/> { " (" + result.keterangan_register_persil + ")" }</td>
            <td className="text-center">{ result.nama_pj_persil + " (" + result.telp_pj_persil + ")" }</td>            
            <td className="text-center">
              <button type="button" id="btnDownload" className="btn btn-outline-dark" onClick={this.handleDownload(result.dok_lapangan_filePath, result.dok_lapangan_fileName)}>
                <FontAwesomeIcon icon="fa-solid fa-image" />
              </button>
            </td>
          </tr>          
        )
      })      
      this.setState({
        paperNumber: response.data.number,
        pageCount: Math.ceil(data.length / this.state.perPage),
        postData
      })      
    })    
  }
  
  componentDidMount = () => {
    if( !cookies.get('udatxu') ) {
      Swal.fire({
        title: 'Info!',
        text: 'Login Expired. Kindly Re-Login',
        icon: 'info',
        confirmButtonText: 'Okay',
        confirmButtonColor: 'Orange',
        allowOutsideClick: true,
        backdrop: true,
        allowEscapeKey: true,
        allowEnterKey: true            
      }).then(result =>  {
        if(result.isConfirmed) {
          window.location.reload()
        }
      })  
    } else { 
      this.receivedData()
    }       
  }

  render() {
    return(
      <div className="accordion">
        <div className="accordion-item">
          <h2 className="accordion-header" id="panelsStayOpen-headingOne">
            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
              Pendataan Simak Pengawasan Bangunan
            </button>
          </h2>
          <div id="panelsStayOpen-collapseOne" className="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingOne">
            <div className="accordion-body">
              <div className="content row g-2">                     
                <div className="navigation-search col-sm-12 col-md-10 col-lg-10">
                  <input type="text" className="form-control" id="search" placeholder="Cari Data Persil (coming soon)" />
                </div>
                <div className="navigation-search col-sm-12 col-md-2 col-lg-2">
                  <Link to="/addsimak">
                    <button type="button" id="btnSearch" className="w-100 btn btn-outline-secondary">
                      <FontAwesomeIcon icon="fa-solid fa-magnifying-glass-location" fixedWidth /> Cari
                    </button>
                  </Link>
                </div>                
                <div className="navigation-add">
                  <Link to="/addsimak">
                    <button type="submit" id="btnSubmit" className="w-100 btn btn-success">
                      <FontAwesomeIcon icon="fa-solid fa-file-circle-plus" fixedWidth /> Tambah Daftar Pengawasan
                    </button>
                  </Link>
                </div>                  
                <div><hr /></div>
                <div className="navigation-pagination">
                  <ReactPaginate
                      previousLabel={'Prev'}
                      nextLabel={'Next'}
                      pageCount={this.state.pageCount || 0}
                      onPageChange={this.handlePageClick}
                      containerClassName={'pagination'}
                      activeClassName={'active'}
                  />
                </div> 
                <div className="wrapper-table-product table-responsive my-3" style={{ overflow: "auto"}}>              
                  <table className="table table-bordered table-hover table-light mb-0">
                    <thead className="thead-light">
                      <tr>
                        <th scope="col" className="text-center" style={{ width: "5%" }}>#</th>
                        <th scope="col" className="text-center" style={{ width: "30%" }}>Tanggal & Kode Daftar</th>                        
                        <th scope="col" className="text-center" style={{ width: "30%" }}>Alamat Persil & Keterangan</th>
                        <th scope="col" className="text-center" style={{ width: "25%" }}>Penanggung Jawab Persil</th>                        
                        <th scope="col" className="text-center" style={{ width: "10%" }}>Dokumentasi</th>
                      </tr>
                    </thead>
                    <tbody className="table-body" id="table-body">
                      { this.state.postData }
                    </tbody>
                  </table>
                </div>                    
              </div>
            </div>
          </div>                 
        </div>        
      </div>          
    )
  }
}

export default DashLapangan